import React, { useState, useEffect } from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";
import axios, { post } from 'axios';
import { MultiSelect } from "react-multi-select-component";

export default function UpdateProduct() {

    const [code, setCode] = useState();
    const [name, setName] = useState();
    const [netPrice, setNetPrice] = useState();
    const [tax, setTax] = useState();
    const [grossPrice, setGrossPrice] = useState();
    const [unit, setUnit] = useState();
    const [barcode, setBarcode] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [alertQuantity, setAlertQuantity] = useState();
    const [quantityAvailable, setQuantityAvailable] = useState();
    const [parent, setParent] = useState([]);
    const [sub, setSub] = useState([]);
    const [Imagedata, setImagedata] = useState("");
    const [progress, setProgress] = useState();
    const [rows,setRows]=useState([]);
    const [srows,setSRows]=useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesCheck, setRolesCheck] = useState([]);
    const [check, setCheck] = useState();
    let data= localStorage.getItem('ProductFileName');
    const id=data;
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "productfetchindividual/"+id).then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                setCode(resp.code);
                setName(resp.name);
                setNetPrice(resp.netPrice);
                setTax(resp.tax);
                setGrossPrice(resp.grossPrice);
                setUnit(resp.unit);
                setBarcode(resp.barcode);
                setCategory(resp.category);
                setSubCategory(resp.subCategory);
                setAlertQuantity(resp.alertQuantity);
                setQuantityAvailable(resp.quantityAvailable);
                setCheck(resp.check);
            })
        })
    }, []);
    const handleChange = file => {
        setImagedata(file[0]);

    }
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "parentcategory").then((result) => {
            result.json().then((resp) => {
                setParent(resp);


            })
        })
    }, []);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "subcategory").then((result) => {
            result.json().then((resp) => {
                setSub(resp);
            })
        })
    }, []);
    function UpdateProduct() {
        // e.preventDefault();
        const fData = new FormData();
        fData.append('Image', Imagedata);
        axios.post(process.env.React_App_Api_Url + 'updateproduct/'+id+ '/' + code + '/' + name + '/' +
            netPrice + '/' + tax + '/' + grossPrice + '/' + unit + '/' + barcode + '/' + category + '/' + subCategory + '/' +
            alertQuantity + '/' + quantityAvailable+'/'+ check+'/'+ rolesId,
            fData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
        // localStorage.setItem('document', JSON.stringify(Name));
        // console.log("ok", Imagedata)

    }
    // const data = localStorage.getItem('document')
    // setFData(data);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
            result.json().then((resp) => {
                setRoles(resp);


            })
        })
    }, []);

    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "supplierprolist").then((result) => {
            result.json().then((resp) => {
                setSRows(resp);

            })
        })
    }, []);
    const rolesId=[];
    
for (let index = 0; index < rolesCheck.length; index++) {
let element = rolesCheck[index];
rolesId[index] = element['id'];
}
roles.map((element, index) =>
<>
    {element['value'] = element['id']}
    {  element['label'] = element['roleName']}

</>
)
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-shopping-cart icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Update Product
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    {/* {progress && <ProgressBar now={progress} label={`${progress}%`} style={{ width: '72%', height: '40px' }} />} */}
                                    <h5 class="card-title"></h5>
                                    {/* <form class="was-validated"> */}
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom01">Code</label>
                                                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} class="form-control" placeholder="Enter Code" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Name</label>
                                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter Name" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Image</label>
                                                <div class="input-group">
                                                    <input type="file" name="image" id="image" onChange={(e) => handleChange(e.target.files)} class="form-control" placeholder="Choose Image" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >Net Price</label>
                                                <input type="text" value={netPrice} onChange={(e) => setNetPrice(e.target.value)} class="form-control" placeholder="Net Price" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Tax</label>
                                                <input type="text" value={tax} onChange={(e) => setTax(e.target.value)} class="form-control" placeholder="Tax" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label >Gross Price</label>
                                                <input type="text" value={grossPrice} onChange={(e) => setGrossPrice(e.target.value)} class="form-control" placeholder="Enter Gross Price" required />
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label>Unit</label>
                                                <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} class="form-control" placeholder="Unit" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label >Barcode Symbology</label>
                                                <input type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)} class="form-control" />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Category</label>

                                                <select value={category} onChange={(e) => setCategory(e.target.value)} className="form-control" >
                                                    <option>
                                                        Select one
                                                    </option>
                                                    {
                                                        parent.map((data) => {
                                                            return (
                                                                <>
                                                                    <option>
                                                                        {data.categoryName}
                                                                    </option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >Sub Category</label>
                                                <select value={subCategory} className="form-control" onChange={(e) => setSubCategory(e.target.value)}>
                                                    <option>
                                                        Select one
                                                    </option>
                                                    {
                                                        sub.map((data) => {
                                                            return (
                                                                <>
                                                                    <option>
                                                                        {data.categoryName}
                                                                    </option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Alert Quantity</label>
                                                <input type="text" value={alertQuantity} onChange={(e) => setAlertQuantity(e.target.value)} class="form-control" placeholder="Enter Alert Quantity" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Quantity Available</label>
                                                <input type="text" value={quantityAvailable} onChange={(e) => setQuantityAvailable(e.target.value)} class="form-control" placeholder="Enter Quantity Available" required />
                                            </div>
                                        </div>
                                        <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label>Select Supplier</label>
                                                        <select className="form-control" value={check} onChange={(e) => setCheck(e.target.value)}>
                                                        <option>Select One</option>
                                                            {srows.map((data) => {
                                                                return (
                                                                    <>
                                                                        <option>
                                                                            {data.id}
                                                                            {data.name}
                                                                        </option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>User Roles</label>
                                                        <MultiSelect
                                                    options={roles}
                                                    value={rolesCheck}
                                                    onChange={setRolesCheck}
                                                    labelledBy="Select"
                                                />
                                                       
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary float-right mb-3" onClick={UpdateProduct} type="submit">Update Product</button>                                        
                                                {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <Footer />
        </>
    );
}



