import React, { useState, useEffect } from "react";
import '../main.css';
import { Button, Paper } from "@mui/material";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { color } from "@mui/system";
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UpdateCustomer() {

    const userTemplate = { label: "", name: "", dataType: "" };
    // const byDefault = { label: "Name", name: "Name", dataType: "string" };
    // const [bydefault, setByDefault] = useState(byDefault);
    const [user, setUsers] = useState([userTemplate]);
    const [update, setUpdate] = useState([]);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "customerformfetch").then((result) => {
            result.json().then((resp) => {
                setUpdate(resp);
            })
        })
    }, []);
    const form = user;
    function updateForm(event) {
        event.preventDefault();
        let data = {  update,form };
        fetch(process.env.React_App_Api_Url + "updatecustomerform", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {

            if (result.status === 200) {
                toast.success("Done Successfully", {
                    position: "bottom-right"
                });
            }
            else if (result.status === 409) {
                toast.warn("Form Already Exsists Try to Update!", {
                    position: "bottom-right",

                });
            }

            else {
                toast.error("Something Wrong", {
                    position: "bottom-right",

                });
            }

        });

    }

    const addUser = () => {
        setUsers([...user, userTemplate]);
    }
    const onUpdate=(e,index)=>{
        const updatedlabel = update.map((update, i) => index == i ? Object.assign(update, { [e.target.name]: e.target.value }
            ) : update);
            setUpdate(updatedlabel);
            const updatedname = update.map((update, i) => index == i ? Object.assign(update, { [e.target.name]: e.target.value }
            ) : update);
            setUpdate(updatedname);
            const updatedtype = update.map((update, i) => index == i ? Object.assign(update, { [e.target.name]: e.target.value }
            ) : update);
            setUpdate(updatedtype);
    }
    const onChange = (e, index1) => {
        const updatedlabel = user.map((user, i) => index1 == i ? Object.assign(user, { [e.target.name]: e.target.value }
        ) : user);
        setUsers(updatedlabel);
        const updatedname = user.map((user, i) => index1 == i ? Object.assign(user, { [e.target.name]: e.target.value }
        ) : user);
        setUsers(updatedname);
        const updatedtype = user.map((user, i) => index1 == i ? Object.assign(user, { [e.target.name]: e.target.value }
        ) : user);
        setUsers(updatedtype);
    }
    const removeUser = (index1) => {
        const filteredUser = [...user];
        filteredUser.splice(index1, 1);
        setUsers(filteredUser);
    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-users icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Update Customer Form
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <Container className="mt-5">

                                        <Paper container={Box} p={4}>
                                           
                                            {update.map((data, index) => {
                                                return(
                                                <Grid container spacing={3} key={index} className="mt-3 pl-2 pr-2">

                                                    <Grid item md={4}>
                                                        <TextField
                                                            label="Label"
                                                            placeholder="Enter Label"
                                                            variant="outlined"
                                                            name="label"
                                                            value={data.label}
                                                            onChange={e => onUpdate(e, index)}
                                                            fullWidth
                                                            aria-required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <TextField
                                                            label="Name"
                                                            placeholder="Enter Name"
                                                            variant="outlined"
                                                            name="name"
                                                            value={data.name}
                                                            onChange={e => onUpdate(e, index)}
                                                            fullWidth
                                                            aria-required
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <select variant="outlined" name="dataType" 
                                                        onChange={e => onUpdate(e, index)}
                                                            fullWidth className="form-control"
                                                             style={{ height: '55px' }}
                                                             value={data.dataType}
                                                             required>
                                                            <option>Select Data Type</option>
                                                            <option>int</option>
                                                            <option>string</option>
                                                            <option>varchar</option>
                                                            <option>char</option>
                                                            <option>text</option>
                                                            <option>boolean</option>
                                                        </select>
                                                    </Grid>

                                                </Grid>
                                                )
                                            })

                                            }
                                            {user.map((user, index) => (
                                                <Grid container spacing={3} key={index} className="mt-3 pl-2 pr-2">
                                                    <Grid item md={4}>
                                                        <TextField
                                                            label="Label"
                                                            placeholder="Enter Label"
                                                            variant="outlined"
                                                            name="label"
                                                            onChange={e => onChange(e, index)}
                                                            fullWidth
                                                            aria-required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <TextField
                                                            label="Name"
                                                            placeholder="Enter Name"
                                                            variant="outlined"
                                                            name="name"
                                                            onChange={e => onChange(e, index)}
                                                            fullWidth
                                                            aria-required
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <select variant="outlined" name="dataType" onChange={e => onChange(e, index)}
                                                            fullWidth className="form-control" style={{ height: '55px' }} required>
                                                            <option>Select Data Type</option>
                                                            <option>int</option>
                                                            <option>string</option>
                                                            <option>varchar</option>
                                                            <option>char</option>
                                                            <option>text</option>
                                                            <option>boolean</option>
                                                        </select>
                                                    </Grid>
                                                    <Grid item md={1}>
                                                        <Button onClick={() => removeUser(index)}
                                                            color="secondary"
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <ToastContainer style={{ margin: "30px" }} />
                                            <Button variant="contained" className="mt-4 mb-2 ml-2" onClick={addUser} >Add Row</Button>
                                            <Button variant="contained" className="mt-4 mb-2 ml-2" onClick={updateForm} >Update Form</Button>

                                        </Paper>
                                    </Container>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



