import React from "react";
import Paper from '@material-ui/core/Paper';
import '../main.css';
import UserSideBar from "../Layouts/UserSideBar";
import UserTopBar from "../Layouts/UserTopBar";
import Footer from "../Layouts/Footer";
import {
ArgumentAxis,
ValueAxis,
Chart,
BarSeries,
} from '@devexpress/dx-react-chart-material-ui';

export default function FinanceChart()
{
    const data = [
        { argument: 'Monday', value: 30 },
        { argument: 'Tuesday', value: 20 },
        { argument: 'Wednesday', value: 10 },
        { argument: 'Thursday', value: 50 },
        { argument: 'Friday', value: 60 },
        ];
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main" style={{ width: "100%" }}>
                    <UserSideBar />
                    <div class="app-main__outer" style={{ width: '100%' }}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-wrench icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Finance Chart
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
	<Paper>
	<Chart
	data={data}
	>
	<ArgumentAxis />
	<ValueAxis />

	<BarSeries valueField="value" argumentField="argument" />
	</Chart>
</Paper>
                                   
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

