import React from "react";
import '../main.css';
import UserSideBar from "../Layouts/UserSideBar";
import UserTopBar from "../Layouts/UserTopBar";
import Footer from "../Layouts/Footer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';



const columns = [
    { id: 'Id', label: <b style={{ color: 'purple' }}>Id</b>, minWidth: 170 },
    { id: 'Name', label: <b style={{ color: 'purple' }}>Name</b>, minWidth: 100 },
    {
        id: 'currency',
        label: <b style={{ color: 'purple' }}>Currency</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'rate',
        label: <b style={{ color: 'purple' }}>Rate</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
  

];

function createData(Id, name,curency,rate) {
    // const density = population / size;
    return { Id, name,curency,rate };
}

const rows = [
    createData('Pakistan', 'PK', 1324171354, 1,
        <>
            <button className="btn btn-primary"> <EditIcon /></button>
            <button className="btn btn-danger"> <DeleteIcon /></button>

        </>),
    createData('China', 'CN', 1403500365, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Italy', 'IT', 60483973, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('United States', 'US', 0, 327167434, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Canada', 'CA', 37602103, 0, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Australia', 'AU', 25475400, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Germany', 'DE', 83019200, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Ireland', 'IE', 4857000, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Mexico', 'MX', 126577691, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Japan', 'JP', 126317000, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('France', 'FR', 67022000, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('United Kingdom', 'GB', 67545757, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Russia', 'RU', 146793744, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Nigeria', 'NG', 200962417, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
    createData('Brazil', 'BR', 210147125, 1, <>
        <button className="btn btn-primary"> <EditIcon /></button>
        <button className="btn btn-danger"> <DeleteIcon /></button>

    </>),
];




export default function TaxRates() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />

                    <div class="app-main__outer" style={{width:'100%'}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-percentage icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Tax Rates
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    {/* <form class="needs-validation" novalidate> */}
                                    <div class="form">
                                        <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />

                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







