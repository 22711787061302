import React,{useRef} from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";
import SignaturePad from 'react-signature-canvas';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Label } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { render } from "@testing-library/react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';



const columns = [
    { id: 'name', label: <b style={{ color: 'purple' }}>Name</b>, minWidth: 100 },
    {
        id: 'price',
        label: <b style={{ color: 'purple' }}>Price</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'qty',
        label: <b style={{ color: 'purple' }}>Qty</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'tax',
        label: <b style={{ color: 'purple' }}>Tax</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'subtotal',
        label: <b style={{ color: 'purple' }}>SubTotals</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

    {
        id: 'action',
        label: <b style={{ color: 'purple' }}>Action</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

];

function createData(name, price, Qty, tax, subtotal, action) {
    // const density = population / size;
    return { name, price, Qty, tax, subtotal, action };
}

const rows = [
    createData('usman', 1123, 1, 1, 1, 1),
    createData('2', 'usman', 'usman@gmail.com', 1, 1, 1, 1),

];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };


export default function AddRepairs() {
    let sigPad=useRef({});
    let signature='';
    let history = useHistory();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    let d;
    let r;
    let p;
    let a;
    const [srNo, setSrNo] = React.useState();
    const [ref, setRef] = React.useState();
    const [name, setName] = React.useState();
    const [assignedTo, setAssignedTo] = React.useState();
    const [model, setModel] = React.useState();
    const [defect, setDefect] = React.useState();
    const [serviceCharges, setServiceCharges] = React.useState();
    const [closeDate, setCloseDate] = React.useState();
    const [pin, setPin] = React.useState();
    const [comments, setComments] = React.useState();
    const [diagnostics, setDiagnostics] = React.useState();
    // const [signature, setSignature] = React.useState();
    const [status, setStatus] = React.useState();
    const [code, setCode] = React.useState();
    const [date, setDate] = React.useState();
    const [reference, setReferenceNumber] = React.useState();
    const [paidBy, setPaidBy] = React.useState();
    const [amount, setAmount] = React.useState();
    console.log('dddd',date);
    function AddRepair(event) {
        event.preventDefault();
       signature= sigPad.current.toDataURL();
        let data = { srNo, ref, name, assignedTo, model, defect, serviceCharges, closeDate, pin, comments, diagnostics, signature, status, code,date,reference,paidBy,amount };
        fetch(process.env.React_App_Api_Url + "addrepair", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        });
        

    }

    function pay()
    {
        d=date;
        r=reference;
        p=paidBy;
        a=amount;
        // let item = { date,reference,paidBy,amount };
        // fetch(process.env.React_App_Api_Url + "addpayment", {
        //     method: 'Post',
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Accept": "application/json"
        //     },
        //     body: JSON.stringify(item)
        // }).then((result) => {
        //     console.warn("result", result);

        // });

    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main" style={{ width: "100%" }}>
                    <UserSideBar />
                    <div class="app-main__outer" style={{ width: '100%' }}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-wrench icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Add Repair
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <div className="row w-100">
                                        <div className="col-md-12">
                                            <form class="was-validated">
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label for="validationCustom01">Serial Number</label>
                                                        <input type="text" onChange={(e) => setSrNo(e.target.value)} class="form-control" placeholder="Serial Number" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label for="validationCustom02">Reference Number</label>
                                                        <input type="text" onChange={(e) => setRef(e.target.value)} class="form-control" placeholder="Reference Number" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Customer Name</label>
                                                        <div class="input-group">
                                                            <input type="text" onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Name" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label >Assigned_to</label>
                                                        <input type="text" onChange={(e) => setAssignedTo(e.target.value)} class="form-control" placeholder="Assigned_to" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Model</label>
                                                        <input type="text" onChange={(e) => setModel(e.target.value)} class="form-control" placeholder="Model" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Defect</label>
                                                        <input type="text" onChange={(e) => setDefect(e.target.value)} class="form-control" placeholder="Defect" required />
                                                    </div>
                                                </div>
                                                
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label >Service Charges</label>
                                                        <input type="text" onChange={(e) => setServiceCharges(e.target.value)} class="form-control" placeholder="Service Charges" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Expected_Close_Date</label>
                                                        <input type="date" onChange={(e) => setCloseDate(e.target.value)} class="form-control" placeholder="Expected_Close_Date" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label >Pin</label>
                                                        <input type="text" onChange={(e) => setPin(e.target.value)} class="form-control" placeholder="Pin" required />
                                                    </div>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="contained-button-file"
                                                />
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-8">
                                            <input type="text" placeholder="Search Replacement Item From Inventory" className="form-control h-75 mb-4" />
                                            </div>
                                            <div className="col-md-4">
                                            <button className="btn btn-primary w-50 h-75">Search</button>
                                            </div>
                                        </div>
                                            <div class="form">
                                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                    <TableContainer sx={{ maxHeight: 440 }}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {columns.map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            style={{ minWidth: column.minWidth }}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows
                                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    .map((row) => {
                                                                        return (
                                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                                {columns.map((column) => {
                                                                                    const value = row[column.id];
                                                                                    return (
                                                                                        <TableCell key={column.id} align={column.align}>
                                                                                            {column.format && typeof value === 'number'
                                                                                                ? column.format(value)
                                                                                                : value}
                                                                                        </TableCell>
                                                                                    );
                                                                                })}
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                        component="div"
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Paper>
                                                <div className="mt-2 w-100 bg-warning">
                                                    <h5 className="text-dark p-3">
                                                        Total = 0.01$
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="text-primary mt-2">Custom Feilds</h4><br />
                                    <div className="row w-100 mb-2">

                                        <div className="col-md-4">
                                            <label>Comments</label>
                                            <textarea onChange={(e) => setComments(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Diagnostics</label>
                                            <textarea onChange={(e) => setDiagnostics(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Signature</label>
                                            {/* <SignatureCanvas onChange={(e) => setSignature(e.target.value)} penColor='blue'
                                                canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }} style={{ border: '10' }} /> */}
                                            <SignaturePad
                                            //  refs={(ref) => { setSignature( ref ) }} 
                                             ref={sigPad} 
                                             canvasProps={{ width: 300, height: 100, className: 'sigCanvas', type: 'canvas' }} />


                                            {/* <SignatureCanvas penColor='green'
                                                    canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }} ref={(ref) => { setSigCanvas( ref )}} /> */}

                                        </div>
                                    </div>
                                    <div className="row w-100 mb-4">
                                        <div className=" col-md-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Status</label>
                                                    <input type="text" onChange={(e) => setStatus(e.target.value)} class="form-control" placeholder="Status" required />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Code</label>
                                                    <input type="text" onChange={(e) => setCode(e.target.value)} class="form-control" placeholder="Code" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-md-4 mt-4">
                                            <label>Send SMS</label>
                                            <Switch {...label} defaultChecked color="secondary" />
                                            <label>Send Email</label>
                                            <Switch {...label} defaultChecked color="secondary" />
                                        </div>
                                    </div>
                                    <div className="row w-100 ml-md-5 ml-sm-1">
                                        <div className="col-md-6">
                                            <div>
                                                <Button variant="outlined" class="btn btn-primary"
                                                    style={{ fontSize: '120%', width: '136px' }}
                                                    onClick={handleClickOpen}>
                                                    Add Payment
                                                </Button>
                                                <label>
                                                    <Button variant="contained" class="btn btn-primary ml-1"
                                                        style={{ fontSize: '120%', width: '136px' }}
                                                        color="primary" type="submit">
                                                        Check List
                                                    </Button>
                                                </label>
                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                        Add Payment
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <div className="row w-100">
                                                            <div className="col-md-6">
                                                                <label>Date</label><br />
                                                                <input type="date" onChange={(e)=>setDate(e.target.value)} className="form-control" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Reference Number</label>
                                                                <input type="text" onChange={(e)=>setReferenceNumber(e.target.value)} placeholder="Reference Number" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row w-100 my-5">
                                                            <div className="col-md-6">
                                                                <label>Paid By</label>
                                                                <input type="text" onChange={(e)=>setPaidBy(e.target.value)} placeholder="Paid By" className="form-control" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Amount</label>
                                                                <input type="text" onChange={(e)=>setAmount(e.target.value)} placeholder="Amount" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={pay}>
                                                            Submit
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" className=""
                                                    color="primary"
                                                    component="span">
                                                    Upload Files
                                                </Button>
                                            </label>
                                            <label>
                                                <button class=" btn btn-primary ml-1" onClick={AddRepair} variant="contained"
                                                    style={{ fontSize: '120%', width: '136px' }}
                                                    color="primary"
                                                    type="submit">
                                                    Save
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



