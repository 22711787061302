
import React from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';



const columns = [
    { id: 'Id', label: <b style={{ color: 'purple' }}>Id</b>, minWidth: 170 },
    { id: 'sr', label: <b style={{ color: 'purple' }}>SR.NO</b>, minWidth: 100 },    { id: 'name', label: <b style={{ color: 'purple' }}>Name</b>, minWidth: 100 },
    { id: 'ref', label: <b style={{ color: 'purple' }}>ReferenceNumber</b>, minWidth: 100 },

    { id: 'name', label: <b style={{ color: 'purple' }}>Name</b>, minWidth: 100 },
    {
        id: 'ass',
        label: <b style={{ color: 'purple' }}>Assigned_To</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'model',
        label: <b style={{ color: 'purple' }}>Model</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'defect',
        label: <b style={{ color: 'purple' }}>Defect</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'sc',
        label: <b style={{ color: 'purple' }}>ServiceCharges</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'cd',
        label: <b style={{ color: 'purple' }}>Closed_Date</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'pin',
        label: <b style={{ color: 'purple' }}>Pin</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'state',
        label: <b style={{ color: 'purple' }}>State</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'comm',
        label: <b style={{ color: 'purple' }}>Comments</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'dg',
        label: <b style={{ color: 'purple' }}>Diagnostics</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'sig',
        label: <b style={{ color: 'purple' }}>Signature</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'Code',
        label: <b style={{ color: 'purple' }}>Code</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'status',
        label: <b style={{ color: 'purple' }}>Status</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: <b style={{ color: 'purple' }}>Action</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

];

function createData(Id,sr,ref, name,ass,model,defect,sc,cd,pin,state,comm,dg,sig,status,code, action) {
    // const density = population / size;
    return { Id,sr,ref, name,ass,model,defect,sc,cd,pin,state,comm,dg,sig,status,code, action};
}

const rows = [
    createData('1', 'usman', 'usman@gmail.com', 1, 1, 1, 1, 1, 3, 3, 3, 3, 3, 3,1,1,
        <>
            <button className="btn btn-primary"> <EditIcon /></button>
            <button className="btn btn-danger"> <DeleteIcon /></button>

        </>),
    createData('2', 'usman', 'usman@gmail.com', 1, 1, 1, 1, 1, 3, 3, 3, 3, 3, 3,1,1,
        <>
            <button className="btn btn-primary"> <EditIcon /></button>
            <button className="btn btn-danger"> <DeleteIcon /></button>

        </>),

];
export default function RepairList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer" style={{width:"100%"}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-wrench icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Repair List
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                               

                                 
                            <div class="form">
                                        <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />

                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    {/* </div> */}
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







