import React, { useState, useEffect } from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";
import { Link, useHistory } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import { keys } from "@material-ui/core/styles/createBreakpoints";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ITEM_HEIGHT = 48;

export default function BranchList() {
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [rows, setRows] = useState([]);
    // const [toggle, setToggle] = useState();
    const [search,setSearch]=useState('');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function update(e) {
        localStorage.setItem('RoleId', JSON.stringify(e));
        console.log(e);
        history.push('/UpdateRole');
    }
    function DeleteBranch(id)
     {
        let data = {id};
        fetch(process.env.React_App_Api_Url + "deleterole", {
            method: 'Delete',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
                result.json().then((resp) => {
                    setRows(resp);
    
                })
            })

        })

    }
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);
            })
        })
    }, []);

    function status(id)
    {
        let data = {id};
        fetch(process.env.React_App_Api_Url + "rolestatus", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.log('ok');

        })        

    }
    function searching()
    {
        fetch(process.env.React_App_Api_Url + "rolesearch"+'/'+search).then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                // localStorage.setItem('BranchList', JSON.stringify(resp));
    
            })
        })
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />

                    <div class="app-main__outer" style={{ width: '100%' }}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-landmark icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Roles List
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    {/* <form class="needs-validation" novalidate> */}
                                    <div class="form">
                                        <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                onChange={(e)=>setSearch(e.target.value)}
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />
                                            <button className="btn btn-primary mt-3" onClick={searching}>Search</button>
                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <table className="table table-sticky">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ color: 'purple' }}>Id</th>
                                                            <th style={{ color: 'purple' }}>Role</th>
                                                            <th style={{ color: 'purple' }}>Status</th>
                                                            <th style={{ color: 'purple' }}>Action</th>
                                                        </tr>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((data, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{data.roleName}</td>
                                                                           
                                                                           
                                                                            <td>
                                                                                {(data.status==='active')?<Switch {...label} onClick={()=>status(data.id)} color="secondary"  defaultChecked  />
                                                                            :<Switch {...label} onClick={()=>status(data.id)} color="secondary" />    
                                                                            }
                                                                                
                                                                                
                                                                                </td>
                                                                               
                                                                            <td>
                                                                           
                                                                                <button onClick={()=>update(data.id)} className="btn btn-primary ml-1 mr-3" > <EditIcon /></button>
                                                                                <button onClick={()=>DeleteBranch(data.id)} className="btn btn-danger ml-1 mr-3"> <DeleteIcon /></button>
                                                                            </td>
                                                                                
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                    </tbody>
                                                </table>

                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







