import * as React from "react";
import '../main.css';
import UserSideBar from "../Layouts/UserSideBar";
import UserTopBar from "../Layouts/UserTopBar";
import Footer from "../Layouts/Footer";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Label } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { render } from "@testing-library/react";
// import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// import { Input } from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas';
import { alpha } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };



export default function ListVouchers() {

    const [expiryDate, setExpiryDate] = React.useState();
    const [cardNumber, setCardNumber] = React.useState();
    const [value, setValue] = React.useState();
    const [customer, setCustomer] = React.useState();
    const [balance, setBalance] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        fetch(process.env.React_App_Api_Url + "voucherlist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);

            })
        })
    }, []);
    function AddVoucher() {
        let data = { expiryDate, cardNumber, value, customer, balance };
        fetch(process.env.React_App_Api_Url + "addvoucher", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);
            fetch(process.env.React_App_Api_Url + "voucherlist").then((result) => {
                result.json().then((resp) => {
                    setRows(resp);

                })
                setOpen(false);
            })
        })





    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />

                    <div class="app-main__outer" style={{ width: '100%' }}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-gift icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            List Vouchers
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                {/* <div class="card-body"> */}

                                <div className="row w-100">
                                    <div className="col-md-12">
                                        <div className="row w-100 mt-3 ml-1">
                                            <div className="col-md-2">
                                                <Button variant="outlined" class="btn btn-primary"
                                                    style={{ fontSize: '120%', width: '136px' }}
                                                    onClick={handleClickOpen}>
                                                    Add Voucher
                                                </Button>

                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                        Add Voucher
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        <div className="row w-100">
                                                            <div className="col-md-6">
                                                                <label>Expiry Date</label><br />
                                                                <input type="date" onChange={(e) => setExpiryDate(e.target.value)} className="form-control" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Card Number</label>
                                                                <input type="text" onChange={(e) => setCardNumber(e.target.value)} placeholder="Card Number" className="form-control" required="required" />
                                                            </div>
                                                        </div>
                                                        <div className="row w-100 my-5">
                                                            <div className="col-md-6">
                                                                <label>Value</label>
                                                                <input type="text" onChange={(e) => setValue(e.target.value)} placeholder="Value" className="form-control" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Customer</label>
                                                                <input type="text" onChange={(e) => setCustomer(e.target.value)} placeholder="Customer" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row w-100 my-5">
                                                            <div className="col-md-6">
                                                                <label>Balance</label>
                                                                <input type="text" onChange={(e) => setBalance(e.target.value)} placeholder="Balance" className="form-control" />
                                                            </div>

                                                        </div>



                                                    </DialogContent>
                                                    <DialogActions>

                                                        <button autoFocus className="btn btn-primary " onClick={AddVoucher}>
                                                            Submit
                                                        </button>
                                                        <button autoFocus className="btn btn-danger " onClick={handleClose}>
                                                            Cancel
                                                        </button>

                                                    </DialogActions>
                                                </BootstrapDialog>

                                            </div>
                                            <div className="col-md-10">
                                                <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                                    <TextField
                                                        id="input-with-icon-textfield"
                                                        label="Search By Name"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <i className="fas fa-search"></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="standard"
                                                    />

                                                </Box>
                                            </div>

                                        </div>

                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <table className="table table-sticky">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ color: 'purple' }}>Id</th>
                                                            <th style={{ color: 'purple' }}>ExpiryDate</th>
                                                            <th style={{ color: 'purple' }}>CardNumber</th>
                                                            <th style={{ color: 'purple' }}>Value</th>
                                                            <th style={{ color: 'purple' }}>Customer</th>
                                                            <th style={{ color: 'purple' }}>Balance</th>
                                                            <th style={{ color: 'purple' }}>Action</th>

                                                        </tr>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((data, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{data.expiryDate}</td>
                                                                            <td>{data.cardNumber}</td>
                                                                            <td>{data.value}</td>
                                                                            <td>{data.customer}</td>
                                                                            <td>{data.balance}</td>

                                                                            <td>

                                                                                <button
                                                                                    //    onClick={()=>update(data.fileName)}
                                                                                    className="btn btn-primary ml-1 mr-3" > <EditIcon /></button>
                                                                                <button
                                                                                    //    onClick={()=>del(data.id)} 
                                                                                    className="btn btn-danger ml-1 mr-3"> <DeleteIcon /></button>
                                                                            </td>

                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                    </tbody>
                                                </table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>

                                    {/* </div> */}
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







