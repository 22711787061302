import React, { useState, useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import { useHistory } from "react-router-dom";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
// const label = { inputProps: { 'aria-label': 'Switch demo' } };
const ITEM_HEIGHT = 48;

export default function CustomersList() {
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [test,setTest] =useState();
    const [rows, setRows] = useState({labels:[],value:[]});
    const [data, setData] = useState([]);
    const [toggle, setToggle] = useState("true");
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "customerlist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                console.log('resp resp', resp);
            })
        })
    }, []);

    function update(e) {
        localStorage.setItem('CustomerId', JSON.stringify(e));
        console.log(e);
        history.push('/UpdateCustomer');
    }
    function del(id) {
        let data = { id };
        fetch(process.env.React_App_Api_Url + "deletecustomer", {
            method: 'Delete',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            fetch(process.env.React_App_Api_Url + "customerlist").then((result) => {
                result.json().then((resp) => {
                    setRows(resp);

                })
            })

        })

    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer" style={{ width: '100%' }}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-users icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Customers List
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                {/* <div class="card-body"> */}
                                <div class="form">
                                    <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Search By Name"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fas fa-search"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />

                                    </Box>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <table className="table table-sticky">
                                            <th style={{ color: 'purple' }}>Id</th>
                                                {
                                                    
                                                    rows.labels.map((label)=>{
                                                      return(
                                                        <>
                                                            <th style={{ color: 'purple' }}>{label}</th>
                                                        </>
                                                      )  
                                                    })
                                                }
                                                
                                                <tbody>


                                                    {rows.value
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((data, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        {data.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <td>{item.value}</td>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        {/* <td>
                                                                            <Switch {...label} onClick={(e) => setToggle(data.id)} color="secondary" />
                                                                        </td> */}
                                                                        {/* <td>

                                                                            <button
                                                                                onClick={() => update(data.id)}
                                                                                className="btn btn-primary ml-1 mr-3" > <EditIcon /></button>
                                                                            <button
                                                                                onClick={() => del(data.id)}
                                                                                className="btn btn-danger ml-1 mr-3"> <DeleteIcon /></button>
                                                                        </td> */}
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}







