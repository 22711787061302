import React, { useState } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import Success from "../../utils/Success";
import Error from "../../utils/Error";
import ImageUploading from 'react-images-uploading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function CreateBranch() {
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [status, setStatus] = useState('inactive');
    const [sts,setSts]=useState(null);
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    let branchLogo;
    if(images === undefined || images === null || images[0] === undefined || images[0] === null)
    {

    }
    else
    {
        branchLogo=images[0].data_url;
    }
    
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };
    function AddBranch(event) {
        event.preventDefault();
        let data = { name, address , branchLogo, status};
        fetch(process.env.React_App_Api_Url + "createbranch", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("res", result.status);
            // setSts(result.status)
            if(result.status===200)
            {
                toast.success("Done Successfully", {
                    position: "bottom-right"});
            }
            else
            {
                toast.error("Something Wrong", {
                    position: "bottom-right",
                   
                });
            }
            

        })

    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" >
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-landmark icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Create Branch
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <div class="form">
                                        <div className="row was-validated w-75">
                                            <div className="col-md-3 ml-5">
                                            <div class="form-group">
                                                    <label for="pwd">Branch Image:</label>
                                                    <ImageUploading
                                                        
                                                        multiple
                                                        value={images}
                                                        onChange={onChange}
                                                        maxNumber={maxNumber}
                                                        dataURLKey="data_url"
                                                    >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            // onImageRemoveAll,
                                                            // onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            dragProps,
                                                        }) => (
                                                            // write your building UI
                                                            <div className="upload__image-wrapper">
                                                                <button
                                                                    style={isDragging ? { color: 'red' } : undefined}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                    className="btn btn-primary"
                                                                >
                                                                    Upload Logo
                                                                </button>
                                                                &nbsp;
                                                                 {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                                                {imageList.map((image, index) => (
                                                                    <div key={index} className="image-item">
                                                                        <img src={image['data_url']} alt="" width="42px" height="42px" className="my-3 ml-2"/>
                                                                        <div className="image-item__btn-wrapper">
                                                                            {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                                                            <button onClick={() => onImageRemove(index)} className="btn btn-danger">Remove</button>
                                                                        </div>
                                                                    </div>
                                                                ))} 
                                                            </div>
                                                        )}
                                                    </ImageUploading>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div class="form-group">
                                                    <label for="uname">Branch Name:</label>
                                                    <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} id="uname" placeholder="Enter Branch Name" name="uname" required />
                                                </div>
                                                <div class="form-group">
                                                    <label for="pwd">Branch Address:</label>
                                                    <input type="text" class="form-control" onChange={(e) => setAddress(e.target.value)} id="pwd" placeholder="Enter Branch Address" name="pswd" required />
                                                </div>
                                                
                                                <div class="form-group">
                                                </div>
                                                <ToastContainer style={{margin:"30px"}}/>
                                                <button type="submit" onClick={AddBranch} class="btn btn-primary float-right">Create Branch</button>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



