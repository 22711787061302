import React, { useState, useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import axios, { post } from 'axios';
import { MultiSelect } from "react-multi-select-component";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServerStatus from 'react-server-status';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function AddProduct() {

    const [code, setCode] = useState();
    const [name, setName] = useState();
    const [netPrice, setNetPrice] = useState();
    const [tax, setTax] = useState();
    const [grossPrice, setGrossPrice] = useState();
    const [unit, setUnit] = useState();
    const [barcode, setBarcode] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [alertQuantity, setAlertQuantity] = useState();
    const [quantityAvailable, setQuantityAvailable] = useState();
    const [parent, setParent] = useState([]);
    const [sub, setSub] = useState([]);
    const [Imagedata, setImagedata] = useState("");
    const [progress, setProgress] = useState();
    const [rows, setRows] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesCheck, setRolesCheck] = useState([]);
    const [check, setCheck] = useState("1usman");
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();

    // console.log('barcode',barcode);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = file => {
        setImagedata(file[0]);

    }
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "parentcategory").then((result) => {
            result.json().then((resp) => {
                setParent(resp);
            })
        })
    }, []);

    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
            result.json().then((resp) => {
                setRoles(resp);
            })
        })
    }, []);

    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "subcategory").then((result) => {
            result.json().then((resp) => {
                setSub(resp);
            })
        })
    }, []);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "supplierprolist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                console.log('sup sup', resp);
            })
        })
    }, []);
    const rolesId = [];
    // console.log(rows.supplier);
    for (let index = 0; index < rolesCheck.length; index++) {
        let element = rolesCheck[index];
        rolesId[index] = element['id'];
    }
    function AddProduct(event) {
        // console.log('check', check);
        event.preventDefault();
        const fData = new FormData();
        fData.append('Image', Imagedata);
        axios.post(process.env.React_App_Api_Url + 'addproduct/' + code + '/' + name + '/' +
            netPrice + '/' + tax + '/' + grossPrice + '/' + unit + '/' + barcode + '/' + category + '/' + subCategory + '/' +
            alertQuantity + '/' + quantityAvailable + '/' + check + '/' + rolesId,
            fData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
    }).then(result => setData({  resp:result.MSG }))
    console.log('data data',data);
       
        // if (result.status === 200) {
        //    toast.success("Done Successfully", {
        //                 position: "bottom-right"
        //             })
        // }
        // else if (result.status === 409) {
        //     toast.warn("Form Already Exsists Try to Update!", {
        //         position: "bottom-right",

        //     });
        // }

        // else {
        //     toast.error("Something Wrong", {
        //         position: "bottom-right",

        //     });
        // }

        // localStorage.setItem('document', JSON.stringify(Name));
        console.log("ok", Imagedata)
        console.log('fdata', fData);

    }
    // const data = localStorage.getItem('document')
    // setFData(data);
    roles.map((element, index) =>
        <>
            {element['value'] = element['id']}
            {element['label'] = element['roleName']}

        </>

    )

    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-shopping-cart icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Add Product
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div className="row w-100">
                                    <div className="col-md-12 w-100">
                                        <div class="card-body">
                                            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} style={{ width: '72%', height: '40px' }} />} */}
                                            <h5 class="card-title"></h5>
                                            <form class="was-validated">
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label for="validationCustom01">Code</label>
                                                        <input type="text" onChange={(e) => setCode(e.target.value)} class="form-control" placeholder="Enter Code" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Name</label>
                                                        <input type="text" onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter Name" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Image</label>
                                                        <div class="input-group">
                                                            <input type="file" name="image" id="image" onChange={(e) => handleChange(e.target.files)} class="form-control" placeholder="Choose Image" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label >Net Price</label>
                                                        <input type="text" onChange={(e) => setNetPrice(e.target.value)} class="form-control" placeholder="Net Price" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label >Gross Price</label>
                                                        <input type="text" onChange={(e) => setGrossPrice(e.target.value)} class="form-control" placeholder="Enter Gross Price" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Unit</label>
                                                        <input type="text" onChange={(e) => setUnit(e.target.value)} class="form-control" placeholder="Unit" required />
                                                    </div>


                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label>Tax</label>
                                                        <input type="text" onChange={(e) => setTax(e.target.value)} class="form-control" placeholder="Tax" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">

                                                        <label>Barcode Symbology</label><span className="ml-5 text-danger">scan{<QrCodeScannerIcon onClick={handleClickOpen} />}</span>


                                                        {/* <Button variant="outlined" class="btn btn-primary"
                                                    style={{ fontSize: '120%', width: '136px' }}
                                                    onClick={handleClickOpen}>
                                                    Add Payment
                                                </Button> */}
                                                        <BootstrapDialog
                                                            onClose={handleClose}
                                                            aria-labelledby="customized-dialog-title"
                                                            open={open}
                                                        >
                                                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                                Barcode Scanner
                                                            </BootstrapDialogTitle>
                                                            <DialogContent dividers>

                                                                <div className="row w-100">
                                                                    <center>
                                                                        <BarcodeScannerComponent
                                                                            width={300}
                                                                            height={300}
                                                                            onUpdate={(err, result) => {
                                                                                setBarcode(result.text)
                                                                                //   if (result) setData(result.text);
                                                                                //   else setData("Not Found");
                                                                            }}
                                                                        />
                                                                        <p>Barcode: {barcode}</p>
                                                                    </center>
                                                                </div>

                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button autoFocus onClick={handleClose}>
                                                                    Submit
                                                                </Button>
                                                            </DialogActions>
                                                        </BootstrapDialog>


                                                        <input type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)} class="form-control" />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Category</label>

                                                        <select onChange={(e) => setCategory(e.target.value)} className="form-control" >
                                                            <option>
                                                                Select one
                                                            </option>
                                                            {
                                                                parent.map((data) => {
                                                                    return (
                                                                        <>
                                                                            <option>
                                                                                {data.categoryName}
                                                                            </option>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label >Sub Category</label>
                                                        <select className="form-control" onChange={(e) => setSubCategory(e.target.value)}>
                                                            <option>
                                                                Select one
                                                            </option>
                                                            {
                                                                sub.map((data) => {
                                                                    return (
                                                                        <>
                                                                            <option>
                                                                                {data.categoryName}
                                                                            </option>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Alert Quantity</label>
                                                        <input type="text" onChange={(e) => setAlertQuantity(e.target.value)} class="form-control" placeholder="Enter Alert Quantity" required />
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>Quantity Available</label>
                                                        <input type="text" onChange={(e) => setQuantityAvailable(e.target.value)} class="form-control" placeholder="Enter Quantity Available" required />
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-4 mb-3">
                                                        <label>Select Supplier</label>
                                                        <select className="form-control" onChange={(e) => setCheck(e.target.value)}>
                                                            <option>Select One</option>
                                                            {rows.map((data) => {
                                                                return (
                                                                    <>
                                                                        <option>
                                                                            {data.id}
                                                                            {data.name}
                                                                        </option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <label>User Roles</label>
                                                        <MultiSelect
                                                            options={roles}
                                                            value={rolesCheck}
                                                            onChange={setRolesCheck}
                                                            labelledBy="Select"
                                                        />

                                                    </div>
                                                </div>
                                                <ToastContainer style={{ margin: "30px" }} />
                                                <button class="btn btn-primary float-right mb-3" onClick={AddProduct} type="submit">Add Product</button>
                                            </form>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <Footer />
        </>
    );
}



