import React, { useState , useEffect } from "react";
import '../main.css';
import { Link, useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function TopNavigationBar() {
    let history = useHistory();
    const [age, setAge] = React.useState('');
    const [branchData , setBranchData]=useState([]);
    let dt=localStorage.getItem('branchId');
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "fetchindividual/"+dt).then((result) => {
            result.json().then((resp) => {
                setBranchData(resp);
                
            })
        })
    }, []);   

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    function logout() {
        localStorage.clear();
        history.push('/Login');
        window.location.reload();
    }

    let data = JSON.parse(localStorage.getItem('Roles'));
    let Reports;
    let Expense;
    let Pos;
    let Settings;
    let Configurations;

    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element === "7") {
            Reports = 7;
        }
        if (element === "8") {
            Expense = 8;
        }
        if (element === "9") {
            Pos = 9;
        }
        if (element === "10") {
            Settings = 10;
        }
        if (element === "11") {
            Configurations = 11;
        }
    }
    const repo = () => {
        if (Reports === 7) {
            return (
                <>
                    <FormControl variant="standard" className="text-dark" sx={{ m: 1, minWidth: 100 }}>

                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-file mr-2"></i>Reports</p>
                        </InputLabel>
                        <Select

                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                        >
                            <MenuItem value="" className="bg-dark text-light">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserStockChart' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Stock Chart
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserFinanceChart' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>Finance Chart
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserQuantityAlerts' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>Quantity Alerts
                                </Link></MenuItem>

                        </Select>
                    </FormControl>
                </>
            )
        }
    }

    const exp = () => {
        if (Expense === 8) {
            return (
                <>
                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-money-bill-alt mr-2"></i>Expense</p>
                        </InputLabel>
                        <Select

                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                        >
                            <MenuItem value="" className="bg-dark text-light">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserAddExpense' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Add Expense
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserAddExpenseCategory' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>Add Expense Category
                                </Link>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserExpCategoryList' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>Expense Category List
                                </Link>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserExpenseList' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>Expense List
                                </Link>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </>
            )
        }

    }

    const pos = () => {
        if (Pos === 9) {
            return (
                <>
                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-terminal mr-2"></i>POS</p>
                        </InputLabel>
                        <Select

                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                        >
                            <MenuItem value="" className="bg-dark text-light">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserListSales' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>List Sales
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserListVouchers' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>List Vouchers
                                </Link></MenuItem>

                        </Select>
                    </FormControl>
                </>
            )
        }
    }

    const set = () => {
        if (Settings === 10) {
            return (
                <>
                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fa fa-cog mr-2"></i>Setting</p>
                        </InputLabel>
                        <Select

                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                        >
                            <MenuItem value="" className="bg-dark text-light">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserTaxRates' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Tax Rates
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserSMSGateways' className="text-light"  >
                                    <i class="metismenu-icon">
                                    </i>SMS Gateways
                                </Link></MenuItem>

                        </Select>
                    </FormControl>
                </>
            )
        }
    }

    const conf = () => {
        if (Configurations === 11) {
            return (
                <>

                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 130 }}>

                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-cogs mr-2"></i>Configuration</p>
                        </InputLabel>
                        <Select

                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={age}
                            onChange={handleChange}
                            label="Age"
                        >

                            <MenuItem className="bg-dark">
                                <Link to='/UserEmailSettings' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Email Setting
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserSMSSettings' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>SMS Setting
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserUploadLogo' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Upload Logo
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserActivityLogs' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Activity Logs
                                </Link></MenuItem>
                            <MenuItem className="bg-dark">
                                <Link to='/UserEmailTemplates' className="text-light">
                                    <i class="metismenu-icon">
                                    </i>Email Templates
                                </Link></MenuItem>
                        </Select>
                    </FormControl>

                </>
            )
        }
    }
    return (
        <>

            {/*  */}
            <div class="app-header header-shadow bg-danger header-text-light ">
                <div class="app-header__logo">
                    <div class="">
                        <img src={branchData.branchLogo} alt="" className="mt-1" style={{ width: '42px' }} />
                    </div>

                    <div class="header__pane ml-auto">
                        <div>
                            {/* <button type="button" class="hamburger close-sidebar-btn hamburger--elastic mt-2" data-class="closed-sidebar">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button> */}
                        </div>
                    </div>
                </div>
                <div class="app-header__mobile-menu ">
                    <div>

                        <button type="button" class="hamburger hamburger--elastic .hamburger-inner mobile-toggle-nav">
                            <span class="hamburger-box ">
                                <span class="hamburger-inner"></span>
                            </span>

                        </button>

                    </div>
                </div>
                <div class="app-header__menu">
                    <span>
                        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span class="btn-icon-wrapper">
                                <i class="fa fa-ellipsis-v fa-w-6"></i>
                            </span>

                        </button>
                    </span>
                </div>
                <div class="app-header__content" style={{ height: '400px' }}>

                    <div class="app-header-left">
                        <div class="header-btn-lg pr-0">
                            <div class="widget-content p-0 ">
                                <div class="widget-content-wrapper ">
                                    <div class="widget-content-left">
                                        <div class="btn-group">
                                            <div className="row w-100">
                                                <div className="col-md-12">
                                                    {repo()}
                                                    {exp()}
                                                    {pos()}
                                                    {set()}
                                                    {conf()}

                                                    {/* </div>
                                                <div className="col-md-5"> */}

                                                </div>
                                            </div>
                                            <div class="ml-3" >

                                                <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                                                    <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                        <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fa fa-sign-out-alt mr-2"></i>Profile</p>
                                                    </InputLabel>
                                                    <Select

                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={age}
                                                        onChange={handleChange}
                                                        label="Age"
                                                    >

                                                        <MenuItem className="bg-dark">
                                                            <Link onClick={logout} className="text-light">
                                                                <i class="metismenu-icon">
                                                                </i>Logout
                                                            </Link></MenuItem>


                                                    </Select>
                                                </FormControl>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>        </div>
                </div>
            </div>







        </>
    );
}



