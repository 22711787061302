import React from "react";
import '../../main.css';
import SideBarNavigation from "../../Layouts/SideBarNavigation";
import TopNavigationBar from "../../Layouts/TopNavigationBar";
import Footer from "../../Layouts/Footer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';



const columns = [
    { id: 'Id', label:<b style={{ color: 'purple' }}>Id</b>, minWidth: 170 },
    {
        id: 'person',
        label: <b style={{ color: 'purple' }}>Person</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'time',
        label: <b style={{ color: 'purple' }}>Time</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'activity',
        label: <b style={{ color: 'purple' }}>Activity</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

];

function createData(Id, time , person, activity) {
    // const density = population / size;
    return {Id, time , person, activity };
}

const rows = [
    createData('Pakistan', 'PK', 1324171354, 1,1
        ),
    createData('China', 'CN', 1403500365, 1, 
       1),
    createData('Italy', 'IT', 60483973, 1, 1),
    createData('United States', 'US', 0, 327167434,1),
    createData('Canada', 'CA', 37602103, 0,1),
    createData('Australia', 'AU', 25475400, 1, 1),
    createData('Germany', 'DE', 83019200, 1, 1),
    createData('Ireland', 'IE', 4857000, 1, 1),
    createData('Mexico', 'MX', 126577691, 1, 1),
    createData('Japan', 'JP', 126317000, 1, 1),
    createData('France', 'FR', 67022000, 1,1),
    createData('United Kingdom', 'GB', 67545757, 1,1),
    createData('Russia', 'RU', 146793744, 1, 1),
    createData('Nigeria', 'NG', 200962417, 1, 1),
    createData('Brazil', 'BR', 210147125, 1, 1),
];




export default function ActivityLogs() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer" style={{width:'100%'}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-history icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                           Activity Logs
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    {/* <form class="needs-validation" novalidate> */}
                                    <div class="form">
                                        <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />

                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







