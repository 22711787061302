import React, { Component, Suspense, lazy, useState } from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";

export default function AddExpenseCategory() {
    const [category,setCategory]=useState();

    function AddExpCategory(event)
    {
        event.preventDefault();
        let data = { category };
        fetch(process.env.React_App_Api_Url + "addexpensecategory", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("res", result.status);
            // setStatus(result.status)

        })  
    }
    
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" >
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-money-bill-alt icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Add Expense Category
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <div class="form">
                                        <div className="row was-validated w-75">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-8">
                                                <div class="form-group">
                                                    <label for="uname">Expense Category</label>
                                                    <input type="text" onChange={(e)=>setCategory(e.target.value)} class="form-control" id="uname" placeholder="Expense Category" name="uname" required />
                                                </div>
                                                <button type="submit" onClick={AddExpCategory} class="btn btn-primary float-right">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



