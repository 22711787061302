import React, { useState, useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import { MultiSelect } from "react-multi-select-component";

export default function UpdateUser() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [signature, setSignature] = useState("");
    const [branch, setBranch] = useState("false");
    const [branchName, setBranchName] = useState([]);
    const [selected, setSelected] = useState([]);
    const [user,setUser]=useState();
    const [roll, setRoll] = useState([]);
    let id=localStorage.getItem('UserId');
    console.log('id id',id);
    const branch_id = [{}];
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "getbranch").then((result) => {
            result.json().then((resp) => {
                setBranchName(resp);
            })
        })
    }, []);
    

    for (let index = 0; index < selected.length; index++) {
        let element = selected[index];
        branch_id[index] = element['id'];
    }
    
    branchName.map((element, index) =>
        <>
            {element['value'] = element['id']}
            { element['label'] = element['name']}

        </>

    )
    function UpdateUser() {
        let data = {id, name, email, password, role, signature, branch_id };
        console.log('inside id',data);
        fetch(process.env.React_App_Api_Url + "updateuser", {
            method: 'Put',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        });
    }
    const [rows,setRows]=useState([]);
    let data= localStorage.getItem('UserId');
    // const id=data;
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "fetchindividualuser/"+data).then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                setName(resp.name);
                setEmail(resp.email);
                setPassword(resp.password);
                setRole(resp.role);
                setSignature(resp.signature);
                
            })
        })
    }, []);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
            result.json().then((resp) => {
                setRoll(resp);
            })
        })
    }, []);
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-user icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Update User
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <form class="was-validated">
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom01">Name</label>
                                                <input type="text" onChange={(e) => setName(e.target.value)} 
                                                value={name}
                                                 class="form-control" placeholder="Enter User Name" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom02">Email</label>
                                                <input type="email" onChange={(e) => setEmail(e.target.value)}
                                                 value={email}
                                                  class="form-control" placeholder="Enter User Email" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Password</label>
                                                <div class="input-group">
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} class="form-control" placeholder="Enter Password" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >Role</label>
                                                <select className="form-control" value={role} onChange={(e)=>setRole(e.target.value)}>
                                                    <option>Select One</option>
                                                    {
                                                        roll.map((data)=>{
                                                            return(
                                                                <>
                                                                    <option>{data.roleName}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Signature</label>
                                                <input type="text" onChange={(e) => setSignature(e.target.value)} value={signature} class="form-control" placeholder="Enter Signature" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom05">Select Branch</label>
                                                <MultiSelect
                                                    options={branchName}
                                                    value={selected}
                                                    
                                                    onChange={setSelected}
                                                    labelledBy="Select"
                                                />
                                            </div>
                                        </div>
                                        <input class="btn btn-primary float-right" onClick={UpdateUser} type="submit" value="Update User" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



