import React, { useEffect, useState, ReactDom } from "react";
// import ReactDom from "react-dom";
import '../main.css';
import { Link } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core/styles";



export default function SideBarNavigation(e) {

    const styles = theme => ({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '1px solid slategrey'
            }
        }
    });
    return (
        <>
            {/* <ThemeProvider theme={theme}> */}

            <div className="app-sidebar sidebar-shadow bg-royal sidebar-text-light " >
                <div className="app-header__logo">

                    <div className="header__pane ml-auto">
                        <div>
                            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-className="closed-sidebar">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                    <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
                </div>
                {/*  */}
                <div className="scrollbar-sidebar " style={{ position: 'relative', overflowX: 'hidden', height: '550px' }}>

                    <div className="app-sidebar__inner " >

                        <ul className="vertical-nav-menu" >
                            <div>
                                <Accordion className="bg-royal mt-3">
                                    <AccordionSummary
                                        className="text-light"
                                        // expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="pe-7s-rocket mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className=" metismenu-icon">
                                            <Link to="/Dashboard" className="text-light">

                                                Dashboard

                                            </Link>
                                        </Typography>
                                    </AccordionSummary>

                                </Accordion>


                            </div>
                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        style={{ textDecorationColor: 'white', color: 'white' }}
                                        // style={{ color: "green" }}
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="pe-7s-diamond mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className=" metismenu-icon text-light">Branches</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Link to={'/CreateBranch'} className="text-light">
                                            Create Branch

                                        </Link>

                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/BranchList'} className="text-light">
                                            Branch List
                                        </Link>
                                    </AccordionDetails>
                                </Accordion>


                            </div>
                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        className="text-light"
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="fas fa-users mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className="text-light">Peoples</Typography>
                                    </AccordionSummary>
                                    <Accordion className="bg-royal">
                                        <AccordionSummary
                                            className="text-light"
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            {/* <i className="fas fa-user mr-3" style={{ fontSize: '20px' }}></i> */}
                                            <Typography className="text-light">Users</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Link to='/CreateUser' className="text-light">
                                                <i className="metismenu-icon">
                                                </i>Add User
                                            </Link>
                                        </AccordionDetails>
                                        <AccordionDetails>
                                            <Link to='/UsersList' className="text-light"  >
                                                <i className="metismenu-icon">
                                                </i>User List
                                            </Link>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="bg-royal">
                                        <AccordionSummary
                                            className="text-light"
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            <Typography className="text-light">Roles</Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Link to='/AddUserRole' className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Add Role
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to='/RoleList' className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Role List
                                        </Link>
                                    </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="bg-royal">
                                    <AccordionSummary
                                            className="text-light"
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            <Typography className="text-light">Customer</Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Link to={'/CreateCustomer'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Create Form
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/UpdateCustomer'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Update Form
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/CustomersList'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Customer List
                                        </Link>
                                    </AccordionDetails>
                                    </Accordion>
                                    <Accordion className="bg-royal">
                                    <AccordionSummary
                                            className="text-light"
                                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            <Typography className="text-light">Supplier</Typography>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Link to={'/AddSupplier'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Create Form
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/UpdateSupplier'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Update Form
                                        </Link>
                                    </AccordionDetails>

                                   
                                    <AccordionDetails>
                                        <Link to={'/SuppliersList'} className="text-light">
                                            <i className="metismenu-icon">
                                            </i>Supplier List
                                        </Link>
                                    </AccordionDetails>
                                    </Accordion>
                                   
                                   
                                </Accordion>


                            </div>
                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        className="text-light"
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="fas fa-list-alt mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className="text-light">Category</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Link to={'/AddCategory'} className="text-light">

                                            Add Category
                                        </Link>

                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/CategoryList'} className="text-light">

                                            Category List
                                        </Link>
                                    </AccordionDetails>

                                </Accordion>


                            </div>

                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        className="text-light"
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="fas fa-shopping-cart mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className="text-light">Product</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Link to={'/AddProduct'} className="text-light">

                                            Add Product
                                        </Link>

                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/ProductsList'} className="text-light">

                                            Product List
                                        </Link>
                                    </AccordionDetails>

                                    <AccordionDetails>
                                        <Link to={'/SupplierHistory'} className="text-light">
                                            History Supplier
                                        </Link>
                                    </AccordionDetails>

                                </Accordion>


                            </div>

                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        className="text-light"
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="fas fa-wrench mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className="text-light">Repairs</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Link to={'/AddRepairs'} className="text-light">

                                            Add Repairs
                                        </Link>

                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/RepairList'} className="text-light">

                                            Repairs List
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/RepairStatuses'} className="text-light">

                                            Repair statuses
                                        </Link>
                                    </AccordionDetails>

                                </Accordion>


                            </div>
                            <div className="mt-3">
                                <Accordion className="bg-royal">
                                    <AccordionSummary
                                        className="text-light"
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="fas fa-file mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className="text-light">Impoters</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Link to={'/ImportCustomer'} className="text-light">

                                            Import Customer
                                        </Link>

                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/ImportProducts'} className="text-light">

                                            Import Product
                                        </Link>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Link to={'/ImportStock'} className="text-light">

                                            Import Stock
                                        </Link>
                                    </AccordionDetails>

                                </Accordion>


                            </div>




                        </ul>


                    </div>

                </div>

            </div>







        </>
    );
}





