import React,{ Component, Suspense, lazy } from "react";
import '../../main.css';
import UserSideBar from "../../Layouts/UserSideBar";
import UserTopBar from "../../Layouts/UserTopBar";
import Footer from "../../Layouts/Footer";

export default function SMSSettings() {
    // const DesktopHeader = lazy(() => import("../Layouts/UserSideBar"));
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" >
                <UserTopBar />
                <div class="app-main">
                
                <UserSideBar />
                
                   
             
            

                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-envelope icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            SMS Settings
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                        <div class="form">
                                            <div className="row was-validated w-75">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                        <div class="form-group">
                                                            <label for="uname">SMS Gateway API</label>
                                                            <select className="form-control">
                                                                <option>
                                                                    no view
                                                                </option>
                                                            </select>                                                           
                                                        </div>
                                                        <div class="form-group">
                                                        <label for="uname">Ipromo</label>
                                                            <select className="form-control">
                                                                <option>
                                                                    no view
                                                                </option>
                                                            </select> 
                                                         
                                                        </div>
                                                        <div class="form-group">
                                                        
                                      
                                    </div>
                                                        <button type="submit" class="btn btn-primary float-right">Save Settings</button>
                                                   

                                                </div>
                                            </div>

                                        </div>



                                  
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}



