import React, { Component, Suspense, lazy, useState, useEffect } from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";

export default function AddExpense() {
    const [amount,setAmount]=useState();
    const [reason,setReason]=useState();
    const [category,setCategory]=useState();
    const [rows,setRows]=useState([]);
    function AddExpense(event) {
        event.preventDefault();
        let data = { amount, reason, category };
        fetch(process.env.React_App_Api_Url + "addexpense", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("res", result.status);
            // setStatus(result.status)

        })

    }
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "expensecategorylist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);
            })
        })
    }, []);

    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" >
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-money-bill-alt icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Add Expense
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <div class="form">
                                        <div className="row was-validated w-75">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-8">
                                                <div class="form-group">
                                                    <label for="uname">Amount</label>
                                                    <input type="text" onChange={(e)=>setAmount(e.target.value)} class="form-control" id="uname" placeholder="Amount" name="uname" required />
                                                </div>
                                                <div class="form-group">
                                                    <label for="pwd">Reason</label>
                                                    <input type="text" onChange={(e)=>setReason(e.target.value)} class="form-control" id="pwd" placeholder="Reason" name="pswd" required />
                                                </div>
                                                <div class="form-group">
                                                    <label for="pwd">Select Category</label>
                                                    <select className="form-control" onChange={(e)=>setCategory(e.target.value)}>
                                                    <option>Select One</option>
                                                        {
                                                            rows.map((data)=>{
                                                               
                                                                return (
                                                                    <>
                                                                        <option>
                                                                            {data.expenseCategory}
                                                                        </option>
                                                                    </>

                                                                )
                                                            })
                                                        }
                                                        
                                                    </select>
                                                </div>
                                                <button type="submit" onClick={AddExpense} class="btn btn-primary float-right">Save Expense</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



