import React from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';



const columns = [
    { id: 'Id', label: <b style={{ color: 'purple' }}>Id</b>, minWidth: 170 },
    { id: 'date', label: <b style={{ color: 'purple' }}>Date</b>, minWidth: 170 },
    { id: 'ref', label: <b style={{ color: 'purple' }}>ReferenceNumber</b>, minWidth: 100 },
    {
        id: 'biller',
        label: <b style={{ color: 'purple' }}>Biller</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'customer',
        label: <b style={{ color: 'purple' }}>Customer</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'saleSt',
        label: <b style={{ color: 'purple' }}>SaleStatus</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'grand',
        label: <b style={{ color: 'purple' }}>GrandTotal</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'paid',
        label: <b style={{ color: 'purple' }}>Paid</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'balance',
        label: <b style={{ color: 'purple' }}>Balance</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'paymentst',
        label: <b style={{ color: 'purple' }}>PaymentStatus</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: <b style={{ color: 'purple' }}>Action</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

];

function createData(Id,date, ref, biller, customer, saleSt,grand,paid,balance,paymentst, action) {
    // const density = population / size;
    return { Id,date, ref, biller, customer, saleSt,grand,paid,balance,paymentst, action};
}

const rows = [
   ];




export default function ListSales() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer" style={{width:'100%'}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-list icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            List Sales
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                {/* <div class="card-body"> */}
                                  
                                    <div className="row w-100">
                                        <div className="col-md-12">
                                            <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Search By Name"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <i className="fas fa-search"></i>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                />

                                            </Box>
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                <TableContainer sx={{ maxHeight: 440 }}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{ minWidth: column.minWidth }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows
                                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((row) => {
                                                                    return (
                                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                            {columns.map((column) => {
                                                                                const value = row[column.id];
                                                                                return (
                                                                                    <TableCell key={column.id} align={column.align}>
                                                                                        {column.format && typeof value === 'number'
                                                                                            ? column.format(value)
                                                                                            : value}
                                                                                    </TableCell>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                    component="div"
                                                    count={rows.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Paper>
                                        </div>

                                    {/* </div> */}
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







