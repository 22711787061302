import React, { useState , useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";

export default function UpdateCategory() {
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [parent, setParent] = useState("none");
    const [sparent, setSParent] = useState([]);
    const [checked, setChecked] = useState(false);    const [rows,setRows]=useState([]);
    let data= localStorage.getItem('CategoryId');
    const id=data;
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "categoryfetchindividual/"+id).then((result) => {
            result.json().then((resp) => {
                setRows(resp);
                console.log('cat cat' , resp)
                setCategoryName(resp.categoryName);
                setCategoryDescription(resp.categoryDescription);
            })
        })
    }, []);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "getparentcategory").then((result) => {
            result.json().then((resp) => {
                setSParent(resp);


            })
        })
    }, []);
    function UpdateCategory() {
        let data = {id, categoryName, categoryDescription, parent };
        fetch(process.env.React_App_Api_Url + "updatecategory", {
            method: 'Put',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        })
    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-list-alt icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Update Category
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="scrollbar-container">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <h5 class="card-title"></h5>
                                        <div class="form">
                                            <div className="row was-validated w-75">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                            
                                                    <div class="form-group">
                                                        <label for="uname">Category Name:</label>
                                                        <input type="text" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} class="form-control" id="uname" placeholder="Enter Category Name" name="uname" required />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="pwd">Category Description:</label>
                                                        <input type="text" value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} class="form-control" id="pwd" placeholder="Enter Category Description" name="pswd" required />
                                                    </div>
                                                    <div class="form-groups ">


                                                        <button onClick={() => setChecked(true)} className="btn btn-info">Make Sub Category</button>
                                                    </div>
                                                    <br /><br />
                                                    <div class="form-groups mb-3">
                                                        {
                                                            checked ?
                                                                <>
                                                                    <label>Select Main Category <button onClick={() => setChecked(false)} className="btn btn-danger">x</button></label>
                                                                    <select className="form-control" onChange={(e)=>setParent(e.target.value)}>
                                                                        <option>Select One</option>
                                                                        {sparent.map((data)=>{
                                                                            return (
                                                                            <option>{data.categoryName}</option>)
                                                                        })}
                                                                        
                                                                    </select>
                                                                </>
                                                                : null
                                                        }
                                                    </div>
                                                    <button type="submit" onClick={UpdateCategory} class="btn btn-primary float-right">Update Category</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



