
import React from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Label } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { render } from "@testing-library/react";
// import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Input } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
// import Input from '@mui/material/Input';



const columns = [
    { id: 'Id', label: <b style={{ color: 'purple' }}>Id</b>, minWidth: 170 },
    { id: 'label', label: <b style={{ color: 'purple' }}>Label</b>, minWidth: 100 },
    
    {
        id: 'action',
        label: <b style={{ color: 'purple' }}>Action</b>,
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },

];

function createData(Id,label, action) {
    // const density = population / size;
    return { Id,label, action};
}
const rows = [
    createData('1', 'Dev',
        <>
            <button className="btn btn-primary"> <EditIcon /></button>
            <button className="btn btn-danger"> <DeleteIcon /></button>

        </>),
     createData('2', 'Acc',
     <>
         <button className="btn btn-primary"> <EditIcon /></button>
         <button className="btn btn-danger"> <DeleteIcon /></button>

     </>),

];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function RepairStatuses()
{
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer"  style={{width:'100%'}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-wrench icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Repair Statuses
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    {/* <form class="needs-validation" novalidate> */}
                                    <div class="form">
                                        <div className="row was-validated w-75">
                                            {/* <div className="col-md-4"></div> */}
                                            <div className="col-md-12">
                                            <Button variant="outlined" class="btn btn-primary mb-5"
                                                    style={{ fontSize: '120%', width: '136px' }}
                                                    onClick={handleClickOpen}>
                                                    Add Status
                                                </Button>
                                            <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                >
                                                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                        Add Status
                                                    </BootstrapDialogTitle>
                                                    <DialogContent dividers>
                                                        
                                                            <div className="row w-100 my-5">
                                                            <div className="col-md-12">
                                                                <label>Label</label>
                                                                <input type="text" placeholder="Label" className="form-control" />
                                                                </div>
                                                               
                                                            </div>
                                                      



                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={handleClose}>
                                                            Submit
                                                        </Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                               
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                                <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />

                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id} align={column.align}>
                                                                                    {column.format && typeof value === 'number'
                                                                                        ? column.format(value)
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                        </div>

                                    {/* </form> */}

                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







