import React, { useState } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import { MultiSelect } from "react-multi-select-component";


export default function UserRoles() {
    const [roleName, setRoleName] = useState();
    const role=[
        {label:'Branch',value:'1'},
        {label:'People',value:'2'},
        {label:'Category',value:'3'},
        {label:'Product',value:'4'},
        {label:'Repair',value:'5'},
        {label:'Importer',value:'6'},
        {label:'Reports',value:'7'},
        {label:'Expense',value:'8'},
        {label:'POS',value:'9'},
        {label:'Settings',value:'10'},
        {label:'Configurations',value:'11'},
        
       
    
    ];
    const [role_id, setRoles] = useState([]);
    const [status,setStatus]=useState('inactive');
    let roles=[{}];

    function UserRole(event) {
        event.preventDefault();
        let data = { roleName , roles , status };
        fetch(process.env.React_App_Api_Url + "userroles", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        })
    }
    if(role_id != undefined || role_id != null)
    {
        for (let index = 0; index < role_id.length; index++) {
            const element = role_id[index];
            roles[index]=element['value'];
            // console.log('elem elem ',element);
            
        }
    }
    else
    {
        console.log('error');
    }
   
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-list-alt icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                        User Roles
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="scrollbar-container">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <h5 class="card-title"></h5>
                                        <div class="form">
                                            <div className="row was-validated w-75">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                    <div class="form-group">
                                                        <label for="uname">Role Name</label>
                                                        <input type="text" onChange={(e) => setRoleName(e.target.value)} class="form-control" id="uname" placeholder="Enter Category Name" name="uname" required />
                                                    </div>

                                                    <div class="form-group">
                                                    <MultiSelect
                                                    options={role}
                                                    value={role_id}
                                                    onChange={setRoles}
                                                    labelledBy="Select"
                                                />
                                                    </div>
                                                    
                                                   
                                                    <button type="submit" onClick={UserRole} className="btn btn-primary float-right">Add Role</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



