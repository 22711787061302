import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";
import { MultiSelect } from "react-multi-select-component";

export default function CreateUser() {
    const [name, setName] = useState("false");
    const [email, setEmail] = useState("false");
    const [password, setPassword] = useState("false");
    const [role, setRole] = useState("false");
    const [roll, setRoll] = useState([]);
    const [signature, setSignature] = useState("false");
    const [branch, setBranch] = useState("false");
    const [branchName, setBranchName] = useState([]);
    const [selected, setSelected] = useState([]);
    const [status, setStatus] = useState('inactive');
    
    // const [branchId, setBranchId] = useState([]);
    //
    const branch_id = [{}];
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "getbranch").then((result) => {
            result.json().then((resp) => {
                setBranchName(resp);
            })
        }) 
    }, []);

    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "userroleslist").then((result) => {
            result.json().then((resp) => {
                setRoll(resp);
            })
        })
    }, []);
    console.log('Branches', branchName);

    for (let index = 0; index < selected.length; index++) {
        let element = selected[index];
        branch_id[index] = element['id'];
    }
    console.log('id', branch_id);
    branchName.map((element, index) =>
        <>
            {element['value'] = element['id']}
            {  element['label'] = element['name']}

        </>

    )
    function AddUser() {
        let data = { name, email, password, role, signature, branch_id,status };
        fetch(process.env.React_App_Api_Url + "createuser", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        });
    }
    console.log('role role',role);
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-user icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Create User
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <form class="was-validated">
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom01">Name</label>
                                                <input type="text" onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Enter User Name" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom02">Email</label>
                                                <input type="email" onChange={(e) => setEmail(e.target.value)} class="form-control" placeholder="Enter User Email" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Password</label>
                                                <div class="input-group">
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} class="form-control" placeholder="Enter Password" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >Role</label>
                                                <select className="form-control" onChange={(e)=>setRole(e.target.value)}>
                                                    <option>Select One</option>
                                                    {
                                                        roll.map((data)=>{
                                                            return(
                                                                <>
                                                                    <option>{data.roleName}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Signature</label>
                                                <input type="text" onChange={(e) => setSignature(e.target.value)} class="form-control" placeholder="Enter Signature" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom05">Select Branch</label>
                                                <MultiSelect
                                                    options={branchName}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="Select"
                                                />
                                            </div>
                                        </div>
                                        {/* <input class="btn btn-primary float-right" onClick={AddUser} type="submit" value="Create User" /> */}
                                        {/* <button class="btn btn-primary float-right" onClick={AddUser}>Create User</button> */}
                                        {/* spacing={2} direction="row" */}
                                        <Stack className="btn btn-primary float-right">
      
      <Button  className="text-light" onClick={AddUser}>Create User</Button>
      
    </Stack>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



