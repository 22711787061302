import React, { useState, useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import { BrowserRouter as Router, Route, Redirect, Link } from 'react-router-dom';
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import { render } from "@testing-library/react";
import { PieChart, Pie, Sector, Cell, Tooltip } from 'recharts';
import { ManOutlined } from "@mui/icons-material";



export default function Dashboard() {
    const [branch, setBranch] = useState([]);
    const [user, setUser] = useState([]);
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [supplier, setSupplier] = useState([]);

    //branch graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "branchgraph").then((result) => {
            result.json().then((resp) => {
                setBranch(resp);
               
            })
        })
    }, []);

    //user graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "usergraph").then((result) => {
            result.json().then((resp) => {
                setUser(resp);
                
            })
        })
    }, []);

    //product graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "productgraph").then((result) => {
            result.json().then((resp) => {
                setProduct(resp);
                
            })
        })
    }, []);

    //category graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "categorygraph").then((result) => {
            result.json().then((resp) => {
                setCategory(resp);
                
            })
        })
    }, []);

    //customer graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "customergraph").then((result) => {
            result.json().then((resp) => {
                setCustomer(resp);
                
            })
        })
    }, []);

    //supplier graph
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "suppliergraph").then((result) => {
            result.json().then((resp) => {
                setSupplier(resp);
            })
        })
    }, []);

    const COLORS = ['#0088FE', '#FF8042'];

    // branch
    const brn = [
        { name: 'Active', value: branch.astatus },
        { name: 'InActive', value: branch.dstatus },
    ];

    const renderActivebrn = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                  <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
 

            </g>
        );
    };

    const [state1, setState1] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter1 = (_, index) => {
        setState1({
            activeIndex: index,
            color: 'orange',
        });
    };

    // User

    const usr = [
        { name: 'Active', value: user.astatus },
        { name: 'InActive', value: user.dstatus },
    ];

    const renderActiveusr = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                 <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>


            </g>
        );
    };

    const [state2, setState2] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter2 = (_, index) => {
        setState2({
            activeIndex: index,
            color: 'orange',
        });
    };


    // product

    const pro = [
        { name: 'Active', value: product.astatus },
        { name: 'InActive', value: product.dstatus },
    ];

    const renderActivepro = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                 {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text> */}


            </g>
        );
    };

    const [state3, setState3] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter3 = (_, index) => {
        setState3({
            activeIndex: index,
            color: 'orange',
        });
    };


    // category

    const cat = [
        { name: 'Active', value: category.astatus },
        { name: 'InActive', value: category.dstatus },
    ];

    const renderActivecat = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                 {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text> */}


            </g>
        );
    };

    const [state4, setState4] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter4 = (_, index) => {
        setState4({
            activeIndex: index,
            color: 'orange',
        });
    };


    // customer

    const cus = [
        { name: 'Active', value: customer.astatus },
        { name: 'InActive', value: customer.dstatus },
    ];

    const renderActivecus = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                 {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text> */}


            </g>
        );
    };

    const [state5, setState5] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter5 = (_, index) => {
        setState5({
            activeIndex: index,
            color: 'orange',
        });
    };



    // supplier 
    const data = [
        { name: 'Active', value: supplier.astatus },
        { name: 'InActive', value: supplier.dstatus },
    ];
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                 {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={2} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text> */}


            </g>
        );
    };

    const [state, setState] = useState({
        activeIndex: 0,
        color: "orange",
    })


    const onPieEnter = (_, index) => {
        setState({
            activeIndex: index,
            color: 'orange',
        });
    };
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    {<SideBarNavigation />}


                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="pe-7s-rocket icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>Dashboard
                                            <div class="page-title-subheading">
                                                {/* This is an example dashboard created using build-in elements and components. */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="page-title-actions">
                                        {/* <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom" class="btn-shadow mr-3 btn btn-dark">
                                            <i class="fa fa-star"></i>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid mt-5 mb-3">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card p-3 mb-2 text-white" style={{
                                            background: "#000428",
                                            background: "-webkit-linear-gradient(to right, #004e92, #000428)",
                                            background: "linear-gradient(to right, #004e92, #000428)"
                                        }}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="d-flex flex-row align-items-center">
                                                            {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                            <div class="ms-2 c-details">
                                                                <h3><i className="page-title-icon pe-7s-diamond icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Branches</h3></i></h3>                                                   </div>
                                                        </div>


                                                    </div>

                                                    <div class="mt-3 pt-3">
                                                        {/* <div className="col-md-6"> */}
                                                        <p class="text-light">Total : {branch.total}</p>
                                                        {/* </div>
                                                <div className="col-md-6"> */}
                                                        <Link to="/BranchList">
                                                            <button class="btn btn-info mt-1">View Details</button>
                                                        </Link>
                                                        {/* </div> */}


                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div>


                                                        {/* <ResponsiveContainer width="100%" height="100%"> */}
                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state1.activeIndex}
                                                                activeShape={renderActivebrn}
                                                                data={brn}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter1}

                                                            > {brn.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                        {/* </ResponsiveContainer> */}

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card p-3 mb-2 text-white" style={{
                                            background: "#000428",
                                            background: "-webkit-linear-gradient(to right, #004e92, #000428)",
                                            background: "linear-gradient(to right, #004e92, #000428)"
                                        }}>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="d-flex flex-row align-items-center">
                                                            {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                            <div class="ms-2 c-details">
                                                                <h3><i className="page-title-icon fas fa-users icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Users</h3></i></h3>                                                   </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-3 pt-3">
                                                        <p class="text-light">Total : {user.total}</p>
                                                        <Link to="/UsersList">
                                                            <button class="btn btn-info mt-1">View Details</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div>

                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state2.activeIndex}
                                                                activeShape={renderActiveusr}
                                                                data={usr}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter2}

                                                            > {usr.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                               </div>

                            <div class="container-fluid mt-5 mb-3">
                                <div class="row">
                                    <div class="col-md-6">
                                    <div class="card p-3 mb-2" style={{
                                                background: "#0f0c29",
                                                background: "-webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29)",
                                                background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)"
                                            }}>
                                            <div className="row">
                                            <div className="col-md-4">
                                                        <div class="d-flex justify-content-between">
                                                            <div class="d-flex flex-row align-items-center">
                                                                {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                                <div class="ms-2 c-details">
                                                                    <h3><i className="page-title-icon fas fa-shopping-cart icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Products</h3></i></h3>                                                   </div>
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <p class="text-light mt-3 pt-3">Total : {product.total}</p>
                                                            <Link to="/ProductsList">
                                                                <button class="btn btn-info mt-1">View Details</button>
                                                            </Link>
                                                        </div>
                                                    </div>


                                                <div className="col-md-8">
                                                    <div>

                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state3.activeIndex}
                                                                activeShape={renderActivepro}
                                                                data={pro}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter3}

                                                            > {pro.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                    <div class="card p-3 mb-2" style={{
                                                background: "#0f0c29",
                                                background: "-webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29)",
                                                background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)"
                                            }}>
                                            <div className="row">
                                            <div className="col-md-4">
                                                        <div class="d-flex justify-content-between">
                                                            <div class="d-flex flex-row align-items-center">
                                                                {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                                <div class="ms-2 c-details">
                                                                    <h3><i className="page-title-icon fas fa-list-alt icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Category</h3></i></h3>                                                   </div>
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <p class="text-light mt-3 pt-3">Total : {category.total}</p>
                                                            <Link to="/CategoryList">
                                                                <button class="btn btn-info mt-1">View Details</button>
                                                            </Link>
                                                        </div>
                                                    </div>


                                                <div className="col-md-8">
                                                    <div>

                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state4.activeIndex}
                                                                activeShape={renderActivecat}
                                                                data={cat}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter4}

                                                            > {cat.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                            </div>





                            {/* Second Container */}
                            <div class="container-fluid mt-5 mb-3">
                                <div class="row">
                                    <div class="col-md-6">
                                    <div class="card p-3 mb-2 " style={{
                                            background: "#8E0E00",
                                            background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",
                                            background: "linear-gradient(to right, #1F1C18, #8E0E00)",
                                        }}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div class="d-flex justify-content-between">
                                                    <div class="d-flex flex-row align-items-center">
                                                    {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                    <div class="ms-2 c-details">
                                                        <h3><i className="page-title-icon fas fa-user-circle icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Customers</h3></i></h3>                                                   
                                                    </div>
                                                </div>

                                                    </div>
                                                    <div>
                                                    <p class="text-light mt-3 pt-3">Total : {customer.total}</p>
                                                <Link to="/CustomersList">
                                                    <button class="btn btn-info mt-1">View Details</button>
                                                </Link>
                                                    </div>
                                                </div>


                                                <div className="col-md-8">
                                                    <div>

                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state5.activeIndex}
                                                                activeShape={renderActivecus}
                                                                data={cus}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter5}

                                                            > {cus.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                    <div class="card p-3 mb-2 " style={{
                                            background: "#8E0E00",
                                            background: "-webkit-linear-gradient(to right, #1F1C18, #8E0E00)",
                                            background: "linear-gradient(to right, #1F1C18, #8E0E00)",
                                        }}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div class="d-flex justify-content-between">
                                                    <div class="d-flex flex-row align-items-center">
                                                    {/* <div class="icon"> <i class="bx bxl-mailchimp"></i> </div> */}
                                                    <div class="ms-2 c-details">
                                                        <h3><i className="page-title-icon fas fa-bicycle icon-gradient bg-mean-fruit" style={{ fontSize: '50px' }}> <h3 class="heading"><br />Suppliers</h3></i></h3>                                                   </div>
                                                </div>

                                                    </div>
                                                    <div>
                                                    <p class="text-light mt-3 pt-3">Total : {supplier.total}</p>
                                                <Link to="/SuppliersList">
                                                    <button class="btn btn-info mt-1">View Details</button>
                                                </Link>
                                                    </div>
                                                </div>


                                                <div className="col-md-8">
                                                    <div>

                                                        <PieChart width={300} height={310} className="text-info">
                                                            <Pie
                                                                activeIndex={state.activeIndex}
                                                                activeShape={renderActiveShape}
                                                                data={data}
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={60}
                                                                outerRadius={80}
                                                                // fill={state.color}
                                                                dataKey="value"
                                                                onMouseEnter={onPieEnter}

                                                            > {data.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}</Pie>

                                                        </PieChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    );
}



