import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function App() {
  const [data, setData] = React.useState();

  return (
    <>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
            setData(result.text)
        //   if (result) setData(result.text);
        //   else setData("Not Found");
        }}
      />
      <p>{data}</p>
    </>
  );
}

export default App;