import React,{useState,useEffect} from "react";
// import '../../env';
import  './login.css';
import {useHistory} from "react-router-dom";

export default function Login()
{
    const [username,setUserName]=useState("null");
    const [password,setPassword]=useState("null");
    const [msg,setmsg]=useState("");
    const [error,seterror]=useState("");
    let history=useHistory();
    async function login ()
    {
        if(username==="" || password==="")
        {
            history.push('/');
        }
       
        let item={username,password}
        let result = await fetch(process.env.React_App_Api_Url+"adlogin", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)

        });
        result = await result.json();
        console.log(result)
        localStorage.setItem('AdminLogin',result.AdminLogin);
        if(result.AdminLogin===null || result.AdminLogin===undefined )
        {
            history.push('/');
            seterror(result.AdminLogin);
            
        } 
        else
        {
            history.push('/Dashboard');
        }   
        
    }
   
    async function userlogin ()
    {
        if(username==="" || password==="")
        {
            history.push('/');
        }
       
       
        else
        {
            let item={username,password}
        let result = await fetch(process.env.React_App_Api_Url+"login", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)

        });
        result = await result.json();
        if(result.UserLogin===null || result.UserLogin===undefined )
        {
            history.push('/');
            setmsg(result.UserLogin);
           
        } 
        else
        {
            
            localStorage.setItem('UserLogin',result.UserLogin.token);
            localStorage.setItem('Roles',result.roles[0].roles);
            localStorage.setItem('BranchRedirect',result.branch);
            history.push('/UserCheckBranch');
        }
        }   
        
    }
   return (
       
         
            
            <div class="container-fluid register">
                <div class="row">
                    <div class="col-md-3 register-left">
                        {/* <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" /> */}
                        <img src="../images/prince.png" alt="" className="img" style={{width:'50%'}} />
                        {/* <h3>Welcome</h3> */}
                        {/* <p>You are 30 seconds away from earning your own money!</p> */}
                        {/* <input type="submit" name="" value="Login" /><br /> */}
                    </div>

                    <div class="col-md-9 col-sm-12 register-right">
                        <center>
                            <ul class="nav nav-tabs nav-justified w-75" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active w-100" style={{height:'40px'}} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><h5 className="m-5 p-5 h">Member Login</h5></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link w-100" style={{height:'40px'}} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><h5 className="m-5 p-5 h">Admin Login</h5></a>
                                </li>
                            </ul>

                        </center><br /><br />
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                               
                                <h3 class="register-heading">Login as a Member</h3>
                              
                                <div class="row register-form">
                               
                                    <div class="col-md-3"></div>

                                    <div class="col-md-6">
                                    {(msg===null || msg===undefined)?<div className="alert alert-danger w-100 text-dark">Please Check Your Credentials Again</div>
                                    :""
                                }
                                        <input type="email" class="form-control" onChange={(e)=>setUserName(e.target.value)} placeholder="Your Email *" defaultvalue="" /><br />
                                        <input type="password" class="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Password *" defaultvalue="" />
                                        <input type="submit" class="btnRegister" onClick={userlogin} value="Login" />

                                    </div>


                                </div>
                            </div>
                            <div class="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <h3 class="register-heading">Login as a Admin</h3>
                                <div class="row register-form">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                    {(error===null || error===undefined)?<div className="alert alert-danger w-100 text-dark">Please Check Your Credentials Again</div>
                                    :""
                                }

                                        <input type="email" onChange={(e)=>setUserName(e.target.value)} class="form-control" placeholder="Your Email *" defaultvalue="" /><br />
                                        <input type="password"  onChange={(e)=>setPassword(e.target.value)} class="form-control" placeholder="Password *" defaultvalue=""  />
                                        <input type="submit" class="btnRegister" onClick={login} value="Login" />

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
      

       
   ); 
}
