import React, { useState, useEffect } from "react";
import '../main.css';
import { Button, Paper } from "@mui/material";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { color } from "@mui/system";
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";
import AddSupplier from "../../AdminComponents/AddForms/AddSupplier";

export default function CreateCustomer() {
    const [supplierjson,setSupplierJson]=useState([]);
    const [supplier, setSupplier] = useState([]);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "supplierformfetch").then((result) => {
            result.json().then((resp) => {
                setSupplier(resp);
            })
        })
    }, []);
    function AddSupplier(event) {
        event.preventDefault();
        let data = { supplierjson };
        fetch(process.env.React_App_Api_Url + "addsupplier", {
            method: 'Post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        });
    }

  let  onChange=(index,label,value)=>{
    setSupplierJson({...supplierjson,[index]:{label,value}});
    }

    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-users icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Add Supplier
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <form className="was-validated">
                                        <div className="row">
                                            {
                                                supplier.map((data,index) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>
                                                                        {data.label}
                                                                    </label>
                                                                    <input type={data.dataType} class="form-control" key={index} placeholder={data.name} onChange={(e)=>onChange(index,data.label,e.target.value)}/>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )

                                                })

                                            }
                                        </div>
                                        <button class="btn btn-primary float-right mb-3" onClick={AddSupplier} type="submit">Add Supplier</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



