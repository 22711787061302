import React from "react";
import '../../main.css';
import SideBarNavigation from "../../Layouts/SideBarNavigation";
import TopNavigationBar from "../../Layouts/TopNavigationBar";
import Footer from "../../Layouts/Footer";

export default function UploadLogo() {
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-upload icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                        Upload Logo
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                        <div class="form">
                                            <div className="row was-validated w-75">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                        <div class="form-group">
                                                            <label for="uname">Upload Logo</label>
                                                            <input type="file" class="form-control" id="uname" placeholder="" name="uname" required />
                                                           
                                                        </div>
                                                       
                                                       
                                                        <button type="submit" class="btn btn-primary float-right">Save Logo</button>
                                                   

                                                </div>
                                            </div>

                                        </div>



                                  
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}



