import React , { useState } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
// import "bootstrap/dist/css/bootstrap.min.css";
import { ProgressBar } from "react-bootstrap";
import axios, { post } from 'axios';
export default function ImportStock() {
    const [Imagedata, setImagedata] = useState("");
    const [progress, setProgress] = useState();
    const handleChange = file => {
        setImagedata(file[0]);
    }
    function ImportStock() {
        // e.preventDefault();
        const fData = new FormData();
        fData.append('uploaded_file', Imagedata);
        axios.post(process.env.React_App_Api_Url + 'importstock/', 
            fData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
        // localStorage.setItem('document', JSON.stringify(Name));
        // console.log("ok", Imagedata)

    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-store icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                        Import Stock
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                        <div class="form">

                                            <div className="row was-validated w-75">

                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                {progress && <ProgressBar now={progress} label={`${progress}%`} style={{ height: '30px' }} />}

                                                        <div class="form-group my-5">
                                                            <label for="uname" >Import Stock</label>
                                                            <input type="file" onChange={(e) => handleChange(e.target.files)} class="form-control" id="uname" placeholder="" name="uname" required />
                                                           
                                                        </div>
                                                       
                                                       
                                                        <button type="submit" onClick={ImportStock} class="btn btn-primary float-right">Import Stock</button>
                                                   

                                                </div>
                                            </div>

                                        </div>



                                  
                                </div>
                            </div>








                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}



