import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import '../main.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export default function CheckBranch() {
    const [open, setOpen] = React.useState(true);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const handleClose = () => {
        setOpen(false);
    };
    let history = useHistory();
    const [branchName, setBranchName] = useState([]);
    const [value, setValue] = useState([]);
    let data = localStorage.getItem('BranchRedirect');
    let d = JSON.parse(data);
    let ele = [];
    for (let index = 0; index < d.length; index++) {
        const element = d[index];
        ele[index] = element['branches_id'];
    }
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "branchredirect/" + ele).then((result) => {
            result.json().then((resp) => {
                setBranchName(resp);
            })
        })
    }, []);
    function submit() {
        localStorage.setItem('branchId', JSON.stringify(value));
        history.push('/UserDashboard');
    }

    return (
        <>
            <div>

                <BootstrapDialog

                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Select Branch
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className="row w-100">
                            <div className="col-md-4"></div>
                            <div className="col-md-6" style={{ width: '500px', height: '150px' }}>

                                {/* <select className="form-control m-5" style={{width:'300px'}} onChange={(e)=>setValue(e.target.value)}>
                            <option>Select Branch</option> */}
                                {branchName.map((data) => {
                                    return (
                                        <>
                                            <div className="row w-100">
                                                <div className="col-md-8 form-control">
                                                    <label className="">{data.name}</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input type="checkbox" onChange={() => setValue(data.id)} className="form-control" /><br />

                                                </div>
                                            </div>


                                            {/* <option> */}

                                            {/* </option> */}




                                        </>
                                    )
                                })}
                                {/* </select> */}

                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button autoFocus onClick={submit} className="btn btn-primary w-100">
                            Submit
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    )
}