import React from "react";
import { BrowserRouter as Router, Route, Redirect, Link, useHistory } from 'react-router-dom';
import Login from "./Login/Login";

// Admin Routes

import Dashboard from "./AdminComponents/Dashboard/Dashboard";
import CreateBranch from "./AdminComponents/AddForms/CreateBranch";
import CreateUser from "./AdminComponents/AddForms/CreateUser";
import CreateCustomer from "./AdminComponents/AddForms/CreateCustomer";
import AddProduct from "./AdminComponents/AddForms/AddProduct";
import AddSupplier from "./AdminComponents/AddForms/AddSupplier";
import AddCategory from "./AdminComponents/AddForms/AddCategory";
import AddRepairs from "./AdminComponents/AddForms/AddRepairs";
import AddExpense from "./AdminComponents/AddForms/AddExpense";
import AddUserRole from "./AdminComponents/AddForms/AddUserRoles";
import AddExpenseCategory from "./AdminComponents/AddForms/AddExpenseCategory";
import ImportCustomer from "./AdminComponents/Importers/ImportCustomer";
import ImportProducts from "./AdminComponents/Importers/ImportProducts";
import ImportStock from "./AdminComponents/Importers/ImportStock";
import BranchList from "./AdminComponents/Listing/BranchList";
import RoleList from "./AdminComponents/Listing/RoleList";
import UsersList from "./AdminComponents/Listing/UsersList";
import CustomersList from "./AdminComponents/Listing/CustomersList";
import ProductsList from "./AdminComponents/Listing/ProductsList";
import SuppliersList from "./AdminComponents/Listing/SuppliersList";
import SupplierHistory from "./AdminComponents/Listing/SupplierHistory";
import CategoryList from "./AdminComponents/Listing/CategoryList";
import ExpCategoryList from "./AdminComponents/Listing/ExpCategoryList";
import RepairStatuses from "./AdminComponents/Listing/RepairStatuses";
import RepairList from "./AdminComponents/Listing/RepairList";
import ExpenseList from "./AdminComponents/Listing/ExpenseList";
import ActivityLogs from "./AdminComponents/Configuration/Logs/ActivityLogs";
import EmailSettings from "./AdminComponents/Configuration/Settings/EmailSettings";
import SMSSettings from "./AdminComponents/Configuration/Settings/SMSSettings";
import UploadLogo from "./AdminComponents/Configuration/Settings/UploadLogo";
import SMSGateways from "./AdminComponents/Settings/SMSGateways";
import TaxRates from "./AdminComponents/Settings/TaxRates";
import ListSales from "./AdminComponents/POS/ListSales";
import ListVouchers from "./AdminComponents/POS/ListVouchers";
import QuantityAlerts from "./AdminComponents/Charts/QuantityAlerts";
import UpdateBranch from "./AdminComponents/UpdateForms/UpdateBranch";
import UpdateUser from "./AdminComponents/UpdateForms/UpdateUser";
import UpdateRole from "./AdminComponents/UpdateForms/UpdateRole";
import UpdateCustomer from "./AdminComponents/UpdateForms/UpdateCustomer";
import UpdateSupplier from "./AdminComponents/UpdateForms/UpdateSupplier";
import UpdateCategory from "./AdminComponents/UpdateForms/UpdateCategory";
import UpdateProduct from "./AdminComponents/UpdateForms/UpdateProduct";
import UpdateExpense from "./AdminComponents/UpdateForms/UpdateExpense";
import UpdateExpCategory from "./AdminComponents/UpdateForms/UpdateExpCategory";
import FinanceChart from "./AdminComponents/Charts/FinanceChart";
import StockChart from "./AdminComponents/Charts/StockChart";
import Test from "./AdminComponents/AddForms/Test";

// User Routes

import UserDashboard from './UserComponents/Dashboard/Dashboard';
import UserCreateBranch from "./UserComponents/AddForms/CreateBranch";
import UserCheckBranch from "./UserComponents/Dashboard/CheckBranch";
import UserCreateUser from "./UserComponents/AddForms/CreateUser";
import UserCreateCustomer from "./UserComponents/AddForms/CreateCustomer";
import UserAddProduct from "./UserComponents/AddForms/AddProduct";
import UserAddSupplier from "./UserComponents/AddForms/AddSupplier";
import UserAddCategory from "./UserComponents/AddForms/AddCategory";
import UserAddRepairs from "./UserComponents/AddForms/AddRepairs";
import UserAddExpense from "./UserComponents/AddForms/AddExpense";
import UserAddUserRole from "./UserComponents/AddForms/AddUserRoles";
import UserAddExpenseCategory from "./UserComponents/AddForms/AddExpenseCategory";
import UserImportCustomer from "./UserComponents/Importers/ImportCustomer";
import UserImportProducts from "./UserComponents/Importers/ImportProducts";
import UserImportStock from "./UserComponents/Importers/ImportStock";
import UserBranchList from "./UserComponents/Listing/BranchList";
import UserUsersList from "./UserComponents/Listing/UsersList";
import UserCustomersList from "./UserComponents/Listing/CustomersList";
import UserProductsList from "./UserComponents/Listing/ProductsList";
import UserSuppliersList from "./UserComponents/Listing/SuppliersList";
import UserCategoryList from "./UserComponents/Listing/CategoryList";
import UserExpCategoryList from "./UserComponents/Listing/ExpCategoryList";
import UserRepairStatuses from "./UserComponents/Listing/RepairStatuses";
import UserRepairList from "./UserComponents/Listing/RepairList";
import UserExpenseList from "./UserComponents/Listing/ExpenseList";
import UserActivityLogs from "./UserComponents/Configuration/Logs/ActivityLogs";
import UserEmailSettings from "./UserComponents/Configuration/Settings/EmailSettings";
import UserSMSSettings from "./UserComponents/Configuration/Settings/SMSSettings";
import UserUploadLogo from "./UserComponents/Configuration/Settings/UploadLogo";
import UserSMSGateways from "./UserComponents/Settings/SMSGateways";
import UserTaxRates from "./UserComponents/Settings/TaxRates";
import UserListSales from "./UserComponents/POS/ListSales";
import UserRoleList from "./UserComponents/Listing/RoleList";
import UserListVouchers from "./UserComponents/POS/ListVouchers";
import UserQuantityAlerts from "./UserComponents/Charts/QuantityAlerts";
import UserUpdateBranch from "./UserComponents/UpdateForms/UpdateBranch";
import UserUpdateUser from "./UserComponents/UpdateForms/UpdateUser";
import UserUpdateCustomer from "./UserComponents/UpdateForms/UpdateCustomer";
import UserUpdateSupplier from "./UserComponents/UpdateForms/UpdateSupplier";
import UserUpdateCategory from "./UserComponents/UpdateForms/UpdateCategory";
import UserUpdateProduct from "./UserComponents/UpdateForms/UpdateProduct";
import UserUpdateExpense from "./UserComponents/UpdateForms/UpdateExpense";
import UserUpdateExpCategory from "./UserComponents/UpdateForms/UpdateExpCategory";
import UserFinanceChart from "./UserComponents/Charts/FinanceChart";
import UserStockChart from "./UserComponents/Charts/StockChart";


// let history = useHistory();

function App() {
  let data = JSON.parse(localStorage.getItem('Roles'));
  let branch;
  let people;
  let category;
  let product;
  let Repairs;
  let Importers;
  let Reports;
  let Pos;
  let Settings;
  let Configurations;
  if (data === null || data === undefined) {

  }

  else {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element === "1") {
        branch = 1;

      }

      if (element === "2") {
        people = 2;
      }
      if (element === "3") {
        category = 3;
      }
      if (element === "4") {
        product = 4;
      }
      if (element === "5") {
        Repairs = 5;
      }
      if (element === "6") {
        Importers = 6;
      }
      if (element === "7") {
        Reports = 7;
      }
      if (element === "8") {
        Pos = 8;
      }
      if (element === "9") {
        Settings = 9;
      }
      if (element === "10") {
        Configurations = 10;
      }
    }
  }
  const brn = () => {
    if (branch === 1) {
      return (
        <>
          <Route path="/UserCreateBranch" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserCreateBranch />
              )
          )}
          />
          <Route path="/UserBranchList" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserBranchList />
              )
          )}
          />
          <Route path="/UserUpdateBranch" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateBranch />
              )
          )}
          />
        </>
      )
    }
  }

  const peop = () => {

    return (
      <>
        <Route path="/UserCreateUser" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserCreateUser />
            )
        )}
        />
         <Route path="/UserAddSupplier" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserAddSupplier />
            )
        )}
        />
        <Route path="/UserAddUserRole" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserAddUserRole />
            )
        )}
        />
        <Route path="/UserCreatecustomer" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserCreateCustomer />
            )
        )}
        />
        <Route path="/UserUsersList" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserUsersList />
            )
        )}
        />
        <Route path="/UserCustomersList" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserCustomersList />
            )
        )}
        />
         <Route path="/UserRoleList" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserRoleList />
            )
        )}
        />
        <Route path="/UserSuppliersList" render={() => (
          localStorage.getItem('UserLogin') === null
            || localStorage.getItem('UserLogin') === undefined
            ?
            (
              <Redirect to="/Login" />
            )
            :
            (
              <UserSuppliersList />
            )
        )}
        />
        <Route path="/UserUpdateUser" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserAdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateUser />
              )
          )}
          />
          <Route path="/UserUpdateCustomer" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateCustomer />
              )
          )}
          />
          <Route path="/UserUpdateSupplier" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateSupplier />
              )
          )}
          />
      </>
    )

  }

  const cate=()=>
  {
    return (
      <>
       <Route path="/UserAddCategory" render={() => (
      localStorage.getItem('UserLogin') === null
        || localStorage.getItem('UserLogin') === undefined
        ?
        (
          <Redirect to="/Login" />
        )
        :
        (
          <UserAddCategory />
        )
    )}
    />
    <Route path="/UserCategoryList" render={() => (
      localStorage.getItem('UserLogin') === null
        || localStorage.getItem('UserLogin') === undefined
        ?
        (
          <Redirect to="/Login" />
        )
        :
        (
          <UserCategoryList />
        )
    )}
    />
     <Route path="/UserUpdateCategory" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateCategory />
              )
          )}
          />
      </>
    )
   
  }

  const pro = ()=>{
    return (
      <>
        <Route path="/UserAddProduct" render={() => (
      localStorage.getItem('UserLogin') === null
        || localStorage.getItem('UserLogin') === undefined
        ?
        (
          <Redirect to="/Login" />
        )
        :
        (
          <UserAddProduct />
        )
    )}
    />
     <Route path="/UserProductsList" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserProductsList />
              )
          )}
          />
          <Route path="/UserUpdateProduct" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUpdateProduct />
              )
          )}
          />
      </>

      
    )
  
  }

  const rep=()=>{
    return (
      <>
       <Route path="/UserAddRepairs" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserAddRepairs />
              )
          )}
          />
           <Route path="/UserRepairStatuses" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserRepairStatuses />
              )
          )}
          />
          <Route path="/UserRepairList" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserRepairList />
              )
          )}
          />
      </>
    )
  }

const imp=()=>{
  return (
    <>
     <Route path="/UserImportCustomer" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserImportCustomer />
              )
          )}
          />
          <Route path="/UserImportProducts" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserImportProducts />
              )
          )}
          />
          <Route path="/UserImportStock" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserImportStock />
              )
          )}
          />
    </>
  )
}

const repo = ()=>{
  return (
    <>
     <Route path="/UserQuantityAlerts" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserQuantityAlerts />
              )
          )}
          />
           <Route path="/UserFinanceChart" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserFinanceChart />
              )
          )}
          />
          <Route path="/UserStockChart" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserStockChart />
              )
          )}
          />
    </>
  )
}

const exp =()=>{
  return (
    <>
     <Route path="/UserExpCategoryList" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserExpCategoryList />
              )
          )}
          />
           <Route path="/UserAddExpense" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserAddExpense />
              )
          )}
          />
          <Route path="/UserAddExpenseCategory" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserAddExpenseCategory />
              )
          )}
          />
          <Route path="/UserExpenseList" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserExpenseList />
              )
          )}
          />
    </>
  )
}

const pos=()=>{
  return (
    <>
       <Route path="/UserListSales" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserListSales />
              )
          )}
          />
          <Route path="/UserListVouchers" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserListVouchers />
              )
          )}
          />
    </>
  )
}

const sett=()=>{
  return (
    <>
    <Route path="/UserSMSGateways" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserSMSGateways />
              )
          )}
          />
          <Route path="/UserTaxRates" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserTaxRates />
              )
          )}
          />
    </>
  )
}

const conf=()=>{
  return (
    <>
    <Route path="/UserActivityLogs" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserActivityLogs />
              )
          )}
          />
          <Route path="/UserEmailSettings" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserEmailSettings />
              )
          )}
          />
          <Route path="/UserSMSSettings" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserSMSSettings />
              )
          )}
          />
          <Route path="/UserUploadLogo" render={() => (
            localStorage.getItem('UserLogin') === null
              || localStorage.getItem('UserLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UserUploadLogo />
              )
          )}
          />
    </>
  )
}
  return (
    <>
      {(data != null || data != undefined) ?
        <>
          {brn()}
          {peop()}
          {cate()}
          {pro()}
          {rep()}
          {imp()}
          {repo()}
          {exp()}
          {pos()}
          {sett()}
          {conf()}
        </>
        :
        <>
          <Route path="/CreateBranch" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <CreateBranch />
              )
          )}
          />
          <Route path="/CreateUser" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <CreateUser />
              )
          )}
          />
          <Route path="/AddUserRole" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddUserRole />
              )
          )}
          />
          <Route path="/Createcustomer" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <CreateCustomer />
              )
          )}
          />
          <Route path="/AddProduct" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddProduct />
              )
          )}
          />
          <Route path="/AddSupplier" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddSupplier />
              )
          )}
          />
           <Route path="/SupplierHistory" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <SupplierHistory />
              )
          )}
          />
          <Route path="/AddCategory" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddCategory />
              )
          )}
          />

          <Route path="/AddRepairs" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddRepairs />
              )
          )}
          />
          <Route path="/ImportCustomer" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ImportCustomer />
              )
          )}
          />
          <Route path="/ImportProducts" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ImportProducts />
              )
          )}
          />
          <Route path="/ImportStock" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ImportStock />
              )
          )}
          />
          <Route path="/AddExpense" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddExpense />
              )
          )}
          />
          <Route path="/AddExpenseCategory" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <AddExpenseCategory />
              )
          )}
          />
          <Route path="/BranchList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <BranchList />
              )
          )}
          />
          <Route path="/UsersList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UsersList />
              )
          )}
          />

        <Route path="/RoleList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <RoleList />
              )
          )}
          />

          <Route path="/CustomersList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <CustomersList />
              )
          )}
          />
          <Route path="/ProductsList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ProductsList />
              )
          )}
          />
          <Route path="/ExpCategoryList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ExpCategoryList />
              )
          )}
          />
          <Route path="/SuppliersList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <SuppliersList />
              )
          )}
          />
          <Route path="/CategoryList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <CategoryList />
              )
          )}
          />

          <Route path="/RepairStatuses" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <RepairStatuses />
              )
          )}
          />
          <Route path="/RepairList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <RepairList />
              )
          )}
          />
          <Route path="/ExpenseList" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ExpenseList />
              )
          )}
          />

          <Route path="/ActivityLogs" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ActivityLogs />
              )
          )}
          />
          <Route path="/EmailSettings" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <EmailSettings />
              )
          )}
          />
          <Route path="/SMSSettings" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <SMSSettings />
              )
          )}
          />
          <Route path="/UploadLogo" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UploadLogo />
              )
          )}
          />
          <Route path="/SMSGateways" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <SMSGateways />
              )
          )}
          />
          <Route path="/TaxRates" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <TaxRates />
              )
          )}
          />
          <Route path="/ListSales" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ListSales />
              )
          )}
          />
          <Route path="/ListVouchers" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <ListVouchers />
              )
          )}
          />
          <Route path="/QuantityAlerts" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <QuantityAlerts />
              )
          )}
          />
          <Route path="/UpdateBranch" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateBranch />
              )
          )}
          />
          <Route path="/UpdateUser" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateUser />
              )
          )}
          />
          <Route path="/UpdateCustomer" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateCustomer />
              )
          )}
          />

<Route path="/UpdateRole" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateRole />
              )
          )}
          />
          <Route path="/UpdateSupplier" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateSupplier />
              )
          )}
          />
          <Route path="/UpdateCategory" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateCategory />
              )
          )}
          />
          <Route path="/UpdateProduct" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateProduct />
              )
          )}
          />
          <Route path="/UpdateExpense" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateExpense />
              )
          )}
          />

          <Route path="/UpdateExpCategory" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <UpdateExpCategory />
              )
          )}
          />
          <Route path="/FinanceChart" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <FinanceChart />
              )
          )}
          />
          <Route path="/StockChart" render={() => (
            localStorage.getItem('AdminLogin') === null
              || localStorage.getItem('AdminLogin') === undefined
              ?
              (
                <Redirect to="/Login" />
              )
              :
              (
                <StockChart />
              )
          )}
          />
        </>
      }
      <Route exact path="/Login"><Login /></Route>
      <Route exact path="/" ><Login /></Route>
      <Route exact path="/Test" ><Test /></Route>

      <Route path="/UserDashboard" render={() => (
        localStorage.getItem('UserLogin') === null
          || localStorage.getItem('UserLogin') === undefined
          ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UserDashboard />
          )
      )}
      />

<Route path="/UserCheckBranch" render={() => (
        localStorage.getItem('UserLogin') === null
          || localStorage.getItem('UserLogin') === undefined
          ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UserCheckBranch />
          )
      )}
      />


      <Route path="/Dashboard" render={() => (
        localStorage.getItem('AdminLogin') === null
          || localStorage.getItem('AdminLogin') === undefined
          ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <Dashboard />
          )
      )}
      />




    </>
  );
}

export default App;
