import React,{ useState , useEffect } from "react";
import '../main.css';
import SideBarNavigation from "../Layouts/SideBarNavigation";
import TopNavigationBar from "../Layouts/TopNavigationBar";
import Footer from "../Layouts/Footer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

export default function QuantityAlerts() {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "productlist").then((result) => {
            result.json().then((resp) => {
                setRows(resp);

            })
        })
    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    return (
        <>

            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <TopNavigationBar />
                <div class="app-main">
                    <SideBarNavigation />

                    <div class="app-main__outer" style={{width:'100%'}}>
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-exclamation-triangle icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Quantity Alerts
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    {/* <form class="needs-validation" novalidate> */}
                                    <div class="form">
                                        <Box sx={{ '& > :not(style)': { m: 1 } }} className="float-right mr-5 mb-3">

                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Search By Name"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-search"></i>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />

                                        </Box>
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                            <table className="table table-sticky">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ color: 'purple' }}>Id</th>
                                                            <th style={{ color: 'purple' }}>Code</th>
                                                            <th style={{ color: 'purple' }}>Name</th>
                                                            <th style={{ color: 'purple' }}>Alert Quantity</th>
                                                            <th style={{ color: 'purple' }}>Quantity Available</th>
                                                            
                                                        </tr>
                                                        {rows
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((data,index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{index+1}</td>
                                                                            <td>{data.code}</td>
                                                                            <td>{data.name}</td>
                                                                            <td>{data.alertQuantity}</td>
                                                                            <td>{data.quantityAvailable}</td>
                                                                            {/* <td>
                                                                                <Switch {...label} onClick={(e) => setToggle(data.id)} color="secondary" />
                                                                            </td>
                                                                            <td>
                                                                           
                                                                           <button 
                                                                           onClick={()=>update(data.id)}
                                                                           className="btn btn-primary ml-1 mr-3" > <EditIcon /></button>
                                                                           <button 
                                                                           onClick={()=>del(data.id)} 
                                                                           className="btn btn-danger ml-1 mr-3"> <DeleteIcon /></button>
                                                                       </td> */}
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                    </tbody>
                                                </table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Footer />

                    </div>
                </div>
            </div>

        </>
    );
}







