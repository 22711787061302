import React, { useState , useEffect } from "react";
import '../main.css';
import UserTopBar from "../Layouts/UserTopBar";
import UserSideBar from "../Layouts/UserSideBar";
import Footer from "../Layouts/Footer";

export default function UpdateCustomer() {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();
    const [contact, setContact] = useState();
    const [taxNum, setTaxNum] = useState();
    const [EUtaxNum, setEUTaxNum] = useState();
    const [identification, setIdentification] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [postalCode, setPostalCode] = useState();
    const [country, setCountry] = useState();
    const [rows, setRows] = useState([]);
    let data= localStorage.getItem('CustomerId');
    const id=data;
    useEffect(() => {
        fetch(process.env.React_App_Api_Url + "customerfetchindividual/"+data).then((result) => {
            result.json().then((resp) => {
                setRows(resp);
            })
        })
    }, []);
    function UpdateCustomer() {
        let data = {id, name, email, company, contact, taxNum, EUtaxNum, identification, address, city, state, postalCode, country };
        fetch(process.env.React_App_Api_Url + "updatecustomer", {
            method: 'Put',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }).then((result) => {
            console.warn("result", result);

        });
    }
    return (
        <>
            <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <UserTopBar />
                <div class="app-main">
                    <UserSideBar />
                    <div class="app-main__outer">
                        <div class="app-main__inner">
                            <div class="app-page-title">
                                <div class="page-title-wrapper">
                                    <div class="page-title-heading">
                                        <div class="page-title-icon">
                                            <i class="fas fa-users icon-gradient bg-mean-fruit">
                                            </i>
                                        </div>
                                        <div>
                                            Update Customer
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <h5 class="card-title"></h5>
                                    <form class="was-validated">
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom01">Name</label>
                                                <input type="text" onChange={(e) => setName(e.target.value)} defaultValue={rows.name} class="form-control" placeholder="Enter Customer Name" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="validationCustom02">Email</label>
                                                <input type="email" defaultValue={rows.email} onChange={(e) => setEmail(e.target.value)} class="form-control" placeholder="Enter Customer Email" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Company</label>
                                                <div class="input-group">
                                                    <input type="text" defaultValue={rows.company} onChange={(e) => setCompany(e.target.value)} class="form-control" placeholder="Enter Company" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >Contact</label>
                                                <input type="text" defaultValue={rows.contact} onChange={(e) => setContact(e.target.value)} class="form-control" placeholder="Enter Contact Number" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Tax Number</label>
                                                <input type="text" defaultValue={rows.taxNum} onChange={(e) => setTaxNum(e.target.value)} class="form-control" placeholder="Enter Tax Number" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label >EU Tax Number</label>
                                                <input type="text" defaultValue={rows.EUtaxNum} onChange={(e) => setEUTaxNum(e.target.value)} class="form-control" placeholder="EnterTax Number" required />
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label>Identification (ID/Passport Number)</label>
                                                <input type="text" defaultValue={rows.identification} onChange={(e) => setIdentification(e.target.value)} class="form-control" placeholder="Enter Identification" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label >Address</label>
                                                <input type="text" defaultValue={rows.address} onChange={(e) => setAddress(e.target.value)} class="form-control" placeholder="Enter Address" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>City</label>
                                                <input type="text" defaultValue={rows.city} onChange={(e) => setCity(e.target.value)} class="form-control" placeholder="Enter City" required />
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label >State</label>
                                                <input type="text" defaultValue={rows.state} onChange={(e) => setState(e.target.value)} class="form-control" placeholder="Enter State" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Postal Code</label>
                                                <input type="text" onChange={(e) => setPostalCode(e.target.value)} class="form-control" placeholder="Enter Postal Code" required />
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label>Country</label>
                                                <input type="text" defaultValue={rows.country} onChange={(e) => setCountry(e.target.value)} class="form-control" placeholder="Enter Country Name" required />
                                            </div>
                                        </div>
                                        <input class="btn btn-primary float-right" onClick={UpdateCustomer} type="submit" value="Update Customer" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}



