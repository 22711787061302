import React,{useState} from "react";
import '../main.css';
import { Link, useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function TopNavigationBar() {
    let history=useHistory();
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    // const [state,setState]=useState(false);
    function logout()
    {
        localStorage.clear();
        history.push('/Login');
        window.location.reload();
    }
    return (
        <>

            {/*  */}
            <div class="app-header header-shadow bg-danger header-text-light ">
                <div class="app-header__logo">
                    <div class="">
                        <img src="../images/PrinceWhite.png" alt="" className="mt-1" style={{ width: '42px' }} />
                    </div>

                    <div class="header__pane ml-auto">
                        <div>
                            {/* <button type="button" class="hamburger close-sidebar-btn hamburger--elastic mt-2" data-class="closed-sidebar">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </button> */}
                        </div>
                    </div>
                </div>
                <div class="app-header__mobile-menu ">
                    <div>
                       
                        <button type="button" class="hamburger hamburger--elastic .hamburger-inner mobile-toggle-nav">
                            <span class="hamburger-box ">
                                <span class="hamburger-inner"></span>
                            </span>

                        </button>

                    </div>
                </div>
                <div class="app-header__menu">
                    <span>
                        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span class="btn-icon-wrapper">
                                <i class="fa fa-ellipsis-v fa-w-6"></i>
                            </span>

                        </button>
                    </span>
                </div>
                <div class="app-header__content" style={{ height: '400px' }}>

                    <div class="app-header-left">
                        <div class="header-btn-lg pr-0">
                            <div class="widget-content p-0 ">
                                <div class="widget-content-wrapper ">
                                    <div class="widget-content-left">
                                        <div class="btn-group">
                                            <div className="row w-100">
                                                <div className="col-md-12">
                                                    <FormControl variant="standard" className="text-dark" sx={{ m: 1, minWidth: 100 }}>

                                                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-file mr-2"></i>Reports</p>
                                                        </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={age}
                                                            onChange={handleChange}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="" className="bg-dark text-light">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/StockChart' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Stock Chart
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/FinanceChart' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>Finance Chart
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/QuantityAlerts' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>Quantity Alerts
                                                                </Link></MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                                                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-money-bill-alt mr-2"></i>Expense</p>
                                                        </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={age}
                                                            onChange={handleChange}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="" className="bg-dark text-light">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/AddExpense' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Add Expense
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/AddExpenseCategory' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>Add Expense Category
                                                                </Link>
                                                                </MenuItem>
                                                                <MenuItem className="bg-dark">
                                                                <Link to='/ExpCategoryList' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>Expense Category List
                                                                </Link>
                                                                </MenuItem>
                                                                <MenuItem className="bg-dark">
                                                                <Link to='/ExpenseList' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>Expense List
                                                                </Link>
                                                                </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                {/* </div>
                                                <div className="col-md-5"> */}
                                                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                                                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-terminal mr-2"></i>POS</p>
                                                        </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={age}
                                                            onChange={handleChange}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="" className="bg-dark text-light">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/ListSales' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>List Sales
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/ListVouchers' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>List Vouchers
                                                                </Link></MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                                                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fa fa-cog mr-2"></i>Setting</p>
                                                        </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={age}
                                                            onChange={handleChange}
                                                            label="Age"
                                                        >
                                                            <MenuItem value="" className="bg-dark text-light">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/TaxRates' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Tax Rates
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/SMSGateways' className="text-light"  >
                                                                    <i class="metismenu-icon">
                                                                    </i>SMS Gateways
                                                                </Link></MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                {/* </div>
                                                <div className="col-md-2"> */}
                                                    <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 130 }}>

                                                        <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                            <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fas fa-cogs mr-2"></i>Configuration</p>
                                                        </InputLabel>
                                                        <Select

                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={age}
                                                            onChange={handleChange}
                                                            label="Age"
                                                        >

                                                            <MenuItem className="bg-dark">
                                                                <Link to='/EmailSettings' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Email Setting
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/SMSSettings' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>SMS Setting
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/UploadLogo' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Upload Logo
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/ActivityLogs' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Activity Logs
                                                                </Link></MenuItem>
                                                            <MenuItem className="bg-dark">
                                                                <Link to='/EmailTemplates' className="text-light">
                                                                    <i class="metismenu-icon">
                                                                    </i>Email Templates
                                                                </Link></MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div class="ml-3" >

                                                <FormControl variant="standard" className="text-light" sx={{ m: 1, minWidth: 100 }}>

                                                    <InputLabel id="demo-simple-select-standard-label" className="text-light">

                                                        <p className="" style={{ fontSize: '13px' }}><i class="nav-link-icon fa fa-sign-out-alt mr-2"></i>Profile</p>
                                                    </InputLabel>
                                                    <Select

                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={age}
                                                        onChange={handleChange}
                                                        label="Age"
                                                    >

                                                        <MenuItem className="bg-dark">
                                                            <Link onClick={logout} className="text-light">
                                                                <i class="metismenu-icon">
                                                                </i>Logout
                                                            </Link></MenuItem>


                                                    </Select>
                                                </FormControl>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>        
                        </div>
                </div>
            </div>







        </>
    );
}



