import React, { useEffect, useState, ReactDom } from "react";
// import ReactDom from "react-dom";
import '../main.css';
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CssBaseline } from "@material-ui/core/styles";

export default function UserSideBar() {
    let data = JSON.parse(localStorage.getItem('Roles'));
    let branch;
    let people;
    let category;
    let product;
    let Repairs;
    let Importers;


    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element === "1") {
            branch = 1;
        }

        if (element === "2") {
            people = 2;
        }
        if (element === "3") {
            category = 3;
        }
        if (element === "4") {
            product = 4;
        }
        if (element === "5") {
            Repairs = 5;
        }
        if (element === "6") {
            Importers = 6;
        }

    }

    const b = () => {


        if (branch === 1) {
            return (
                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            style={{ textDecorationColor: 'white', color: 'white' }}
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="pe-7s-diamond mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="metismenu-icon text-light">Branches</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link to={'/UserCreateBranch'} className="text-light">
                                Create Branch

                            </Link>

                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserBranchList'} className="text-light">
                                Branch List
                            </Link>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        }
    }
    const p = () => {
        if (people === 2) {
            return (

                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            className="text-light"
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="fas fa-users mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="text-light">Peoples</Typography>
                        </AccordionSummary>
                        <Accordion className="bg-royal">
                            <AccordionSummary
                                className="text-light"
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                {/* <i className="fas fa-user mr-3" style={{ fontSize: '20px' }}></i> */}
                                <Typography className="text-light">Users</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Link to='/UserCreateUser' className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Add User
                                </Link>
                            </AccordionDetails>
                            <AccordionDetails>
                                <Link to='/UserUsersList' className="text-light"  >
                                    <i className="metismenu-icon">
                                    </i>User List
                                </Link>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="bg-royal">
                            <AccordionSummary
                                className="text-light"
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className="text-light">Roles</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Link to='/UserAddUserRole' className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Add Role
                                </Link>
                            </AccordionDetails>
                            <AccordionDetails>
                                <Link to='/UserRoleList' className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Role List
                                </Link>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="bg-royal">
                            <AccordionSummary
                                className="text-light"
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className="text-light">Customer</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Link to={'/UserCreateCustomer'} className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Add Customer
                                </Link>
                            </AccordionDetails>
                          
                            <AccordionDetails>
                                <Link to={'/UserCustomersList'} className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Customer List
                                </Link>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="bg-royal">
                            <AccordionSummary
                                className="text-light"
                                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography className="text-light">Supplier</Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Link to={'/UserAddSupplier'} className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Add Supplier
                                </Link>
                            </AccordionDetails>
                            
                            <AccordionDetails>
                                <Link to={'/UserSuppliersList'} className="text-light">
                                    <i className="metismenu-icon">
                                    </i>Supplier List
                                </Link>
                            </AccordionDetails>
                        </Accordion>


                    </Accordion>


                </div>

            )
        }
    }
    const c = () => {
        if (category === 3) {
            return (

                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            className="text-light"
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="fas fa-list-alt mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="text-light">Category</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link to={'/UserAddCategory'} className="text-light">

                                Add Category
                            </Link>

                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserCategoryList'} className="text-light">

                                Category List
                            </Link>
                        </AccordionDetails>

                    </Accordion>


                </div>

            )
        }
    }
    const pro = () => {
        if (product === 4) {
            return (

                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            className="text-light"
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="fas fa-shopping-cart mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="text-light">Product</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link to={'/UserAddProduct'} className="text-light">

                                Add Product
                            </Link>

                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserProductsList'} className="text-light">

                                Product List
                            </Link>
                        </AccordionDetails>

                    </Accordion>


                </div>

            )
        }
    }
    const rep = () => {
        if (Repairs === 5) {
            return (

                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            className="text-light"
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="fas fa-wrench mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="text-light">Repairs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link to={'/UserAddRepairs'} className="text-light">

                                Add Repairs
                            </Link>

                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserRepairList'} className="text-light">

                                Repairs List
                            </Link>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserRepairStatuses'} className="text-light">

                                Repair statuses
                            </Link>
                        </AccordionDetails>

                    </Accordion>


                </div>
            )
        }
    }

    const imp = () => {
        if (Importers === 6) {
            return (

                <div className="mt-3">
                    <Accordion className="bg-royal">
                        <AccordionSummary
                            className="text-light"
                            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"

                        >
                            <i className="fas fa-file mr-3" style={{ fontSize: '20px' }}></i>
                            <Typography className="text-light">Impoters</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Link to={'/UserImportCustomer'} className="text-light">

                                Import Customer
                            </Link>

                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserImportProducts'} className="text-light">

                                Import Product
                            </Link>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Link to={'/UserImportStock'} className="text-light">

                                Import Stock
                            </Link>
                        </AccordionDetails>

                    </Accordion>


                </div>

            )
        }
    }
    return (
        <>
            {/* <ThemeProvider theme={theme}> */}

            <div className="app-sidebar sidebar-shadow bg-royal sidebar-text-light " >
                <div className="app-header__logo">

                    <div className="header__pane ml-auto">
                        <div>
                            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-className="closed-sidebar">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                    <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
                </div>
                {/*  */}
                <div className="scrollbar-sidebar " style={{ position: 'relative', overflowX: 'hidden', height: '550px' }}>

                    <div className="app-sidebar__inner " >

                        <ul className="vertical-nav-menu" >
                            <div>
                                <Accordion className="bg-royal mt-3">
                                    <AccordionSummary
                                        className="text-light"
                                        // expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <i className="pe-7s-rocket mr-3" style={{ fontSize: '20px' }}></i>
                                        <Typography className=" metismenu-icon">
                                            <Link to="/UserDashboard" className="text-light">

                                                Dashboard

                                            </Link>
                                        </Typography>
                                    </AccordionSummary>

                                </Accordion>


                            </div>
                            {b()}
                            {p()}
                            {c()}
                            {pro()}
                            {rep()}
                            {imp()}

                        </ul>


                    </div>

                </div>

            </div>







        </>
    );
}





