import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';


  
const renderApp = Component => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Component />
      </Router>
      </React.StrictMode>
    ,
    document.getElementById('root')
  );
};

renderApp(App);

// ReactDOM.render(
  
//   <React.StrictMode>
//   <Router>  <App /> </Router>
//   </React.StrictMode>,
  
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
